<template>
  <b-row class="justify-content-center">
    <b-col cols="12">
      <b-card>
        <!-- Statistika qismini yangilaymiz -->
        <b-row class="mb-4">
          <b-col cols="12">
            <div class="d-flex justify-content-between">
              <!-- Kunlik -->
              <b-card class="stat-card daily flex-grow-1 mr-2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="stat-title mb-1">Kunlik</h6>
                    <h3 class="stat-value mb-0">{{ dailyCount }}</h3>
                  </div>
                  <div class="stat-icon">
                    <feather-icon icon="CalendarIcon" size="32"/>
                  </div>
                </div>
                <div class="stat-progress mt-2">
                  <b-progress
                    :value="(dailyCount / totalRows) * 100"
                    variant="success"
                    height="6px"
                  />
                </div>
              </b-card>

              <!-- Haftalik -->
              <b-card class="stat-card weekly flex-grow-1 mr-2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="stat-title mb-1">Haftalik</h6>
                    <h3 class="stat-value mb-0">{{ weeklyCount }}</h3>
                  </div>
                  <div class="stat-icon">
                    <feather-icon icon="ClockIcon" size="32"/>
                  </div>
                </div>
                <div class="stat-progress mt-2">
                  <b-progress
                    :value="(weeklyCount / totalRows) * 100"
                    variant="warning"
                    height="6px"
                  />
                </div>
              </b-card>

              <!-- Yillik -->
              <b-card class="stat-card yearly flex-grow-1 mr-2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="stat-title mb-1">Yillik</h6>
                    <h3 class="stat-value mb-0">{{ yearlyCount }}</h3>
                  </div>
                  <div class="stat-icon">
                    <feather-icon icon="TrendingUpIcon" size="32"/>
                  </div>
                </div>
                <div class="stat-progress mt-2">
                  <b-progress
                    :value="(yearlyCount / totalRows) * 100"
                    variant="danger"
                    height="6px"
                  />
                </div>
              </b-card>

              <!-- Jami -->
              <b-card class="stat-card total flex-grow-1">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="stat-title mb-1">Jami</h6>
                    <h3 class="stat-value mb-0">{{ totalRows }}</h3>
                  </div>
                  <div class="stat-icon">
                    <feather-icon icon="LayersIcon" size="32"/>
                  </div>
                </div>
                <div class="stat-progress mt-2">
                  <b-progress
                    value="100"
                    variant="primary"
                    height="6px"
                  />
                </div>
              </b-card>
            </div>
          </b-col>
        </b-row>

        <!-- Table qismi -->
        <b-table
          :items="items"
          :fields="fields"
          responsive="sm"
          hover
          striped
          small
          class="mb-2 custom-table"
        >
          <template #cell(createdAt)="data">
            <span>{{ formatDate(data.item.createdAt) }}</span>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          @input="fetchData"
          align="center"
          class="my-0"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios'
import { BTable, BPagination, BCard } from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BPagination,
    BCard,
  },
  data() {
    return {
      items: [],
      allItems: [],
      fields: [
        { key: 'id', label: 'ID', thStyle: { width: '80px' } },
        { key: 'threadId', label: 'Thread ID', thStyle: { width: '150px' } },
        { key: 'response', label: 'Javob', thStyle: { width: 'auto' } },
        { key: 'createdAt', label: 'Yaratilgan vaqti', thStyle: { width: '150px' } },
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
    }
  },
  created() {
    this.fetchAllData()
  },
  methods: {
    async fetchAllData() {
      try {
        const response = await axios.get('https://bolajon.mtsh.uz/api/ai-chat/all', {
          params: {
            page: 0,
            size: 1000,
            sort: 'createdAt,desc'
          }
        })
        this.allItems = response.data.content
        this.totalRows = response.data.totalElements
        this.fetchData()
      } catch (error) {
        console.error('Ma\'lumotlarni olishda xatolik:', error)
      }
    },
    async fetchData() {
      try {
        const response = await axios.get('https://bolajon.mtsh.uz/api/ai-chat/all', {
          params: {
            page: this.currentPage - 1,
            size: this.perPage,
            sort: 'createdAt,desc'
          }
        })
        this.items = response.data.content
      } catch (error) {
        console.error('Ma\'lumotlarni olishda xatolik:', error)
      }
    },
    formatDate(dateArray) {
      const date = new Date(
        dateArray[0], // Yil
        dateArray[1] - 1, // Oy (0-indeksli)
        dateArray[2], // Kun
        dateArray[3], // Soat
        dateArray[4], // Daqiqa
        dateArray[5], // Sekund
        dateArray[6] / 1000000 // Millisekund
      )
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')
      return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
    },
  },
  computed: {
    dailyCount() {
      const today = new Date()
      return this.allItems.filter(item => {
        const itemDate = new Date(item.createdAt[0], item.createdAt[1] - 1, item.createdAt[2])
        return itemDate.toDateString() === today.toDateString()
      }).length
    },
    weeklyCount() {
      const today = new Date()
      const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()))
      return this.allItems.filter(item => {
        const itemDate = new Date(item.createdAt[0], item.createdAt[1] - 1, item.createdAt[2])
        return itemDate >= startOfWeek
      }).length
    },
    yearlyCount() {
      const currentYear = new Date().getFullYear()
      return this.allItems.filter(item => item.createdAt[0] === currentYear).length
    }
  }
}
</script>

<style lang="scss" scoped>
.stat-card {
  transition: all 0.3s ease;
  border: none;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  overflow: hidden;
  position: relative;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.2);

    .stat-icon {
      transform: scale(1.1);
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    transition: all 0.3s ease;
  }

  &.daily::before {
    background-color: #28c76f;
  }

  &.weekly::before {
    background-color: #ff9f43;
  }

  &.yearly::before {
    background-color: #ea5455;
  }

  &.total::before {
    background-color: #7367f0;
  }

  .stat-title {
    color: #6e6b7b;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .stat-value {
    color: #5e5873;
    font-size: 1.8rem;
    font-weight: 600;
  }

  .stat-icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.3s ease;

    svg {
      stroke-width: 1.5;
    }
  }

  &.daily .stat-icon {
    color: #28c76f;
    background-color: rgba(40, 199, 111, 0.12);
  }

  &.weekly .stat-icon {
    color: #ff9f43;
    background-color: rgba(255, 159, 67, 0.12);
  }

  &.yearly .stat-icon {
    color: #ea5455;
    background-color: rgba(234, 84, 85, 0.12);
  }

  &.total .stat-icon {
    color: #7367f0;
    background-color: rgba(115, 103, 240, 0.12);
  }

  .stat-progress {
    margin-top: 1rem;
  }
}

.custom-table {
  border-radius: 8px;
  overflow: hidden;

  th {
    background-color: #f8f8f8;
    font-weight: 600;
    color: #6e6b7b;
  }

  td {
    vertical-align: middle;
  }
}

.b-pagination {
  .page-item {
    &.active .page-link {
      background-color: #7367f0;
      border-color: #7367f0;
    }
    .page-link {
      color: #7367f0;
    }
  }
}
</style>
