<template>
  <div class="game-blog">
    <!-- Header section -->
    <b-card class="header-card mb-2">
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="mb-0">O'yinlar katalogi</h2>
        <b-button
          to="/game"
          variant="primary"
          class="d-flex align-items-center">
          <feather-icon icon="PlusCircleIcon" class="mr-1"/>
          Yangi o'yin qo'shish
        </b-button>
      </div>
    </b-card>

    <content-with-sidebar class="main-content" v-loading="loading">
      <!-- Main content -->
      <div class="content-wrapper">
        <!-- Games Grid -->
        <b-row>
          <b-col
            v-for="game in items"
            :key="game.id"
            lg="4"
            md="6"
            sm="12"
            class="mb-2">
            <b-card
              no-body
              class="game-card h-100">
              <b-img
                :src="`https://bolajon.mtsh.uz/api/file-storages/preview/${game.hashId}/avatar.jpg`"
                class="card-img-top game-image"
              />
              <b-card-body>
                <div class="d-flex justify-content-between align-items-start">
                  <h4 class="game-title" v-html="game.titleUz"></h4>
                  <b-badge
                    :variant="tagsColor(game.type)"
                    pill>
                    {{ getCategoryLabel(game.type) }}
                  </b-badge>
                </div>
                <p class="game-description" v-html="game.contentUz"></p>
                <div class="card-actions mt-2">
                  <b-button
                    variant="outline-primary"
                    class="mr-1"
                    @click="editeGameList(game.id)">
                    <feather-icon icon="EditIcon" size="16" class="mr-1"/>
                    Tahrirlash
                  </b-button>
                  <b-button
                    variant="primary"
                    @click="infoGamesList(game.id)">
                    <feather-icon icon="EyeIcon" size="16" class="mr-1"/>
                    Batafsil
                  </b-button>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <!-- Pagination -->
        <div class="w-100 mb-2">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            first-text="«"
            prev-text="‹"
            next-text="›"
            last-text="»"
            class="my-0"
          />
        </div>
      </div>

      <!-- Sidebar -->
      <div
        slot="sidebar"
        class="sidebar-content">
        <b-card class="category-card">
          <h5 class="mb-2">Kategoriyalar</h5>
          <div
            v-for="category in adviceCategoryTypes"
            :key="category.value"
            class="category-item"
            :class="{ active: selectedCategory === category.value }"
            @click="selectCategory(category)">
            <div class="category-icon" :style="{ backgroundColor: category.bgColor }">
              <feather-icon 
                :icon="category.icon"
                size="18"
                :class="selectedCategory === category.value ? 'text-primary' : 'text-muted'"
              />
            </div>
            <span class="ml-2">{{ category.text }}</span>
          </div>
        </b-card>
      </div>
    </content-with-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BImg,
  BCardBody,
  BBadge,
  BFormGroup,
  BPagination,
  BButton,
  BAvatar,
} from 'bootstrap-vue'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'

export default {
  name: 'GameBlog',
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardBody,
    BAvatar,
    BBadge,
    BFormGroup,
    BImg,
    BPagination,
    ContentWithSidebar,
  },
  data() {
    return {
      loading: false,
      items: [],
      selectedCategory: null,
      currentPage: 1,
      perPage: 9,
      totalRows: 0,
      adviceCategoryTypes: [
        { 
          value: 'IJTIMOIY', 
          text: 'Ijtimoiy-emotsional', 
          icon: 'UsersIcon', // ijtimoiy uchun odamlar ikoni
          bgColor: 'rgba(115, 103, 240, 0.12)'
        },
        { 
          value: 'KOGNITIV', 
          text: 'Kognitiv', 
          icon: 'AwardIcon', // aql uchun miya ikoni
          bgColor: 'rgba(234, 84, 85, 0.12)'
        },
        { 
          value: 'TIL', 
          text: 'Til va aloqa', 
          icon: 'MessageCircleIcon', // til uchun xabar ikoni
          bgColor: 'rgba(0, 207, 232, 0.12)'
        },
        { 
          value: 'MOTORIKA', 
          text: 'Motorika', 
          icon: 'ActivityIcon', // motorika uchun harakat ikoni
          bgColor: 'rgba(40, 199, 111, 0.12)'
        },
        { 
          value: null, 
          text: 'Barchasi', 
          icon: 'GridIcon', // barchasi uchun to'r ikoni
          bgColor: 'rgba(186, 191, 199, 0.12)'
        },
      ],
    }
  },
  created() {
    this.getGames()
  },
  methods: {
    handleCategoryChange(category) {
      this.selectedCategory = category
      this.currentPage = 1
      this.getGames()
    },

    async getGames() {
      try {
        this.loading = true
        
        // URL parametrlarini yaratamiz
        const queryParams = new URLSearchParams({
          page: this.currentPage - 1,
          size: this.perPage,
        }).toString()

        // GET metodi bilan so'rov yuboramiz
        const response = await this.$http.post(`/game/find-all?${queryParams}`, {
          data: {
            ageCategoryId: null,
            type: this.selectedCategory,
          },
        })
        
        this.items = response.data.content
        this.totalRows = response.data.totalElements
      } catch (error) {
        console.error('Xatolik yuz berdi:', error)
      } finally {
        this.loading = false
      }
    },

    getCategoryLabel(type) {
      const category = this.adviceCategoryTypes.find(c => c.value === type)
      return category ? category.text : 'Barchasi'
    },

    infoGamesList(id) {
      this.$router.push({
        name: 'game-blog-detail',
        params: { id },
      })
    },

    editeGameList(id) {
      this.$router.push({
        name: 'game',
        params: { id },
      })
    },

    selectCategory(category) {
      this.selectedCategory = category.value
      this.currentPage = 1
      this.getGames()
    },

    tagsColor(tag) {
      switch (tag) {
        case 'IJTIMOIY':
          return 'light-primary'
        case 'KOGNITIV':
          return 'light-danger'
        case 'TIL':
          return 'light-secondary'
        case 'MOTORIKA':
          return 'light-success'
        default:
          return 'light-primary'
      }
    },
  },
  watch: {
    currentPage() {
      this.getGames()
    },
    selectedCategory() {
      this.currentPage = 1
      this.getGames()
    },
  },
}
</script>

<style lang="scss" scoped>
.game-blog {
  .header-card {
    background: linear-gradient(to right, #7367f0, #9e95f5);
    h2 {
      color: white;
    }
  }

  .game-card {
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: none;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 28px rgba(34, 41, 47, 0.2);
    }

    .game-image {
      height: 200px;
      object-fit: cover;
    }

    .game-title {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    .game-description {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .category-card {
    .category-item {
      padding: 0.75rem;
      border-radius: 0.5rem;
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      &:hover {
        background-color: rgba(115, 103, 240, 0.08);
      }

      &.active {
        background-color: rgba(115, 103, 240, 0.12);
        
        .category-icon {
          transform: scale(1.1);
        }
      }

      .category-icon {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        transition: transform 0.2s ease;
      }
    }
  }

  .sidebar-content {
    position: sticky;
    top: 1rem;
  }

  ::v-deep .pagination {
    margin: 2rem 0;
    
    .page-item {
      margin: 0 0.2rem;
      
      .page-link {
        border: none;
        min-width: 36px;
        height: 36px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        color: #6e6b7b;
        background-color: rgba(115, 103, 240, 0.08);
        
        &:hover {
          background-color: rgba(115, 103, 240, 0.12);
          color: #7367f0;
        }
      }
      
      &.active {
        .page-link {
          background-color: #7367f0;
          color: white;
        }
      }
      
      &.disabled {
        .page-link {
          background-color: #efefef;
          color: #b9b9c3;
        }
      }
    }
  }
}
</style>
