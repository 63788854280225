<template>
  <div class="emlash-container">
    <!-- Emlash kategoriyasi uchun modal -->
    <b-modal
      v-model="isCategoryModalVisible"
      :title="selectedCategory ? 'Kategoriyani tahrirlash' : 'Yangi kategoriya'"
      @hidden="resetCategoryForm"
      @ok="handleCategorySubmit"
    >
      <b-form>
        <b-form-group label="Nomi (UZ)">
          <b-form-input v-model="categoryForm.nameUz" required />
        </b-form-group>
        <b-form-group label="Nomi (RU)">
          <b-form-input v-model="categoryForm.nameRu" required />
        </b-form-group>
        <b-form-group label="Nomi (EN)">
          <b-form-input v-model="categoryForm.nameEn" required />
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Emlash uchun modal -->
    <b-modal
      v-model="isEmlashModalVisible"
      :title="selectedEmlash ? 'Emlashni tahrirlash' : 'Yangi emlash'"
      @hidden="resetEmlashForm"
      @ok="handleEmlashSubmit"
    >
      <b-form>
        <b-form-group label="Kategoriya">
          <b-form-select
            v-model="emlashForm.emlashCategoryId"
            :options="categoryOptions"
          />
        </b-form-group>
        <b-form-group label="Nomi (UZ)">
          <b-form-input v-model="emlashForm.nameUz" required />
        </b-form-group>
        <b-form-group label="Nomi (RU)">
          <b-form-input v-model="emlashForm.nameRu" required />
        </b-form-group>
        <b-form-group label="Nomi (EN)">
          <b-form-input v-model="emlashForm.nameEn" required />
        </b-form-group>
        <b-form-group label="Holati">
          <b-form-checkbox v-model="emlashForm.isActive">Faol</b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-card no-body>
      <!-- Header section -->
      <div class="modern-header">
        <div class="d-flex justify-content-between align-items-center flex-wrap gap-2">
          <div class="header-title">
            <h3 class="mb-0">Emlash ma'lumotlari</h3>
          </div>
          <div class="header-actions d-flex gap-2">
            <div class="switch-container d-flex align-items-center">
              <span class="switch-label">Kategoriyalar jadvali</span>
              <label class="switch-wrapper">
                <b-form-checkbox
                  v-model="showCategoriesTable"
                  switch
                ></b-form-checkbox>
              </label>
            </div>
            <b-button
              variant="primary"
              class="d-flex align-items-center"
              @click="showCategoryModal"
            >
              <feather-icon
                icon="FolderPlusIcon"
                size="16"
                class="mr-2"
              />
              <span>Yangi kategoriya</span>
            </b-button>
            <b-button
              variant="success"
              class="d-flex align-items-center"
              @click="showEmlashModal"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-2"
              />
              <span>Yangi emlash</span>
            </b-button>

          </div>
        </div>
      </div>

      <b-card-body>
        <!-- Kategoriyalar jadvali -->
        <b-table
          v-if="showCategoriesTable"
          :items="categories"
          :fields="categoryFields"
          responsive
          striped
          hover
          class="mb-4"
        >
          <template #cell(actions)="row">
            <b-button-group size="sm">
              <b-button
                variant="primary"
                @click="editCategory(row.item)"
              >
                <feather-icon icon="EditIcon" size="16"/>
              </b-button>
              <b-button
                variant="danger"
                @click="deleteCategory(row.item)"
              >
                <feather-icon icon="TrashIcon" size="16"/>
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <!-- Kategoriya filtri va Emlash jadvali faqat showCategoriesTable false bo'lganda ko'rinadi -->
        <template v-if="!showCategoriesTable">
          <!-- Kategoriya filtri -->
          <b-form-group label="Kategoriya bo'yicha filtrlash" class="mb-3">
            <b-form-select
              v-model="selectedCategoryFilter"
              :options="categoryOptions"
            />
          </b-form-group>

          <!-- Emlash jadvali -->
          <b-table
            :items="emlashItems"
            :fields="emlashFields"
            responsive
            striped
            hover
          >
            <template #cell(isActive)="row">
              <b-badge :variant="row.item.isActive === 1 ? 'success' : 'danger'">
                {{ row.item.isActive === 1 ? 'Faol' : 'Faol emas' }}
              </b-badge>
            </template>

            <template #cell(actions)="row">
              <b-button-group size="sm">
                <b-button
                  variant="primary"
                  @click="editEmlash(row.item)"
                >
                  <feather-icon icon="EditIcon" size="16"/>
                </b-button>
                <b-button
                  variant="danger"
                  @click="deleteEmlash(row.item)"
                >
                  <feather-icon icon="TrashIcon" size="16"/>
                </b-button>
              </b-button-group>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
          />
        </template>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BButtonGroup,
  BBadge,
} from 'bootstrap-vue'
import axios from 'axios'


export default {
  name: 'Emlash',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButtonGroup,
    BBadge,
  },
  data() {
    return {
      // Kategoriya uchun
      isCategoryModalVisible: false,
      selectedCategory: null,
      categoryForm: {
        nameUz: '',
        nameRu: '',
        nameEn: '',
      },
      categories: [],

      // Emlash uchun
      isEmlashModalVisible: false,
      selectedEmlash: null,
      emlashForm: {
        nameUz: '',
        nameRu: '',
        nameEn: '',
        emlashCategoryId: null,
        isActive: 1,
        createdAt: null,
      },
      emlashItems: [],
      selectedCategoryFilter: null,

      // Pagination
      currentPage: 1,
      perPage: 10,
      totalRows: 0,

      // Table fields
      emlashFields: [
        { key: 'id', label: 'ID', tdClass: 'text-center' },
        { key: 'nameUz', label: 'Nomi (UZ)' },
        { key: 'nameRu', label: 'Nomi (RU)' },
        { key: 'nameEn', label: 'Nomi (EN)' },
        {
          key: 'isActive',
          label: 'Holati',
          formatter: value => value === 1 ? 'Faol' : 'Faol emas',
        },
        { key: 'actions', label: 'Amallar', tdClass: 'text-right' },
      ],

      // Kategoriya jadvali uchun fields
      categoryFields: [
        { key: 'id', label: 'ID', tdClass: 'text-center' },
        { key: 'nameUz', label: 'Nomi (UZ)' },
        { key: 'nameRu', label: 'Nomi (RU)' },
        { key: 'nameEn', label: 'Nomi (EN)' },
        { key: 'actions', label: 'Amallar', tdClass: 'text-right' },
      ],

      showCategoriesTable: false,
    }
  },
  computed: {
    categoryOptions() {
      return [
        {
          value: null,
          text: 'Barcha kategoriyalar'
        },
        ...this.categories.map(category => ({
          value: category.id,
          text: category.nameUz,
        })),
      ]
    },
  },
  async created() {
    await this.fetchCategories()
    await this.fetchEmlashItems()
  },
  methods: {
    // Kategoriya metodlari
    async fetchCategories() {
      try {
        const response = await this.$http.get('/emlash-category/find-all-list', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        this.categories = response.data
      } catch (error) {
        console.error('Kategoriyalarni yuklashda xatolik:', error)
        this.$bvToast.toast('Kategoriyalarni yuklashda xatolik yuz berdi', {
          title: 'Xato',
          variant: 'danger',
        })
      }
    },

    async handleCategorySubmit() {
      try {
        const payload = { ...this.categoryForm }
        await this.$http.post('/api/emlash-category/save', payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        this.isCategoryModalVisible = false
        await this.fetchCategories()

        const message = this.selectedCategory
          ? 'Kategoriya muvaffaqiyatli yangilandi'
          : 'Yangi kategoriya muvaffaqiyatli qoshildi'

        this.$bvToast.toast(message, {
          title: 'Muvaffaqiyat',
          variant: 'success',
        })

        this.resetCategoryForm()
      } catch (error) {
        this.$bvToast.toast('Xatolik yuz berdi', {
          title: 'Xato',
          variant: 'danger',
        })
      }
    },

    // Emlash metodlari
    async fetchEmlashItems() {
      try {
        const payload = {
          emlashCategoryId: this.selectedCategoryFilter,
        }
        const response = await this.$http.post(
          `/emlash/find-all?page=${this.currentPage - 1}&size=${this.perPage}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          },
        )
        this.emlashItems = response.data.content
        this.totalRows = response.data.totalElements
      } catch (error) {
        console.error('Emlash ma\'lumotlarini yuklashda xatolik:', error)
        this.$bvToast.toast('Ma\'lumotlarni yuklashda xatolik yuz berdi', {
          title: 'Xato',
          variant: 'danger',
        })
      }
    },

    async handleEmlashSubmit() {
      try {
        // Majburiy maydonlarni tekshirish
        if (!this.emlashForm.emlashCategoryId) {
          this.$bvToast.toast('Iltimos, kategoriyani tanlang', {
            title: 'Xato',
            variant: 'warning',
          })
          return
        }

        if (!this.emlashForm.nameUz || !this.emlashForm.nameRu || !this.emlashForm.nameEn) {
          this.$bvToast.toast('Iltimos, barcha nomlarni to\'ldiring', {
            title: 'Xato',
            variant: 'warning',
          })
          return
        }

        const payload = {
          nameUz: this.emlashForm.nameUz,
          nameRu: this.emlashForm.nameRu,
          nameEn: this.emlashForm.nameEn,
          emlashCategoryId: this.emlashForm.emlashCategoryId,
          isActive: this.emlashForm.isActive ? 1 : 0,
        }

        // Tahrirlash uchun id va createdAt ni qo'shamiz
        if (this.selectedEmlash?.id) {
          payload.id = this.selectedEmlash.id
          payload.createdAt = this.emlashForm.createdAt
        }

        await this.$http.post('/emlash/save', payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })

        this.isEmlashModalVisible = false
        await this.fetchEmlashItems()

        this.$bvToast.toast(
          this.selectedEmlash ? 'Emlash muvaffaqiyatli yangilandi' : 'Yangi emlash muvaffaqiyatli qo\'shildi',
          {
            title: 'Muvaffaqiyat',
            variant: 'success',
          },
        )

        this.resetEmlashForm()
      } catch (error) {
        console.error('Saqlashda xatolik:', error.response?.data || error)
        this.$bvToast.toast(error.response?.data?.message || 'Xatolik yuz berdi', {
          title: 'Xato',
          variant: 'danger',
        })
      }
    },

    // Modal ko'rsatish metodlari
    showCategoryModal() {
      this.selectedCategory = null
      this.resetCategoryForm()
      this.isCategoryModalVisible = true
    },

    showEmlashModal() {
      this.selectedEmlash = null
      this.isEmlashModalVisible = true
    },

    // Form tozalash metodlari
    resetCategoryForm() {
      this.categoryForm = {
        id: null,
        nameUz: '',
        nameRu: '',
        nameEn: '',
      }
      this.selectedCategory = null
    },

    resetEmlashForm() {
      this.emlashForm = {
        nameUz: '',
        nameRu: '',
        nameEn: '',
        emlashCategoryId: null,
        isActive: 1,
        createdAt: null,
      }
      this.selectedEmlash = null
    },

    // Tahrirlash va o'chirish metodlari
    editEmlash(item) {
      this.selectedEmlash = item
      this.emlashForm = {
        id: item.id,
        nameUz: item.nameUz,
        nameRu: item.nameRu,
        nameEn: item.nameEn,
        emlashCategoryId: item.emlashCategoryId,
        isActive: item.isActive,
        createdAt: item.createdAt,
      }
      this.isEmlashModalVisible = true
    },

    async deleteEmlash(item) {
      try {
        const result = await this.$bvModal.msgBoxConfirm('Haqiqatan ham o\'chirmoqchimisiz?', {
          title: 'O\'chirishni tasdiqlash',
          okVariant: 'danger',
          okTitle: 'O\'chirish',
          cancelTitle: 'Bekor qilish',
          hideHeaderClose: false,
          centered: true,
        })

        if (result) {
          await axios.delete(`https://bolajon.mtsh.uz/api/emlash/${item.id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          await this.fetchEmlashItems()
          this.$bvToast.toast('Muvaffaqiyatli o\'chirildi', {
            title: 'Muvaffaqiyat',
            variant: 'success',
          })
        }
      } catch (error) {
        this.$bvToast.toast('O\'chirishda xatolik yuz berdi', {
          title: 'Xato',
          variant: 'danger',
        })
      }
    },

    // Kategoriyani tahrirlash
    editCategory(item) {
      this.selectedCategory = item
      this.categoryForm = { ...item }
      this.isCategoryModalVisible = true
    },

    // Kategoriyani o'chirish
    async deleteCategory(item) {
      try {
        const result = await this.$bvModal.msgBoxConfirm('Haqiqatan ham bu kategoriyani o\'chirmoqchimisiz?', {
          title: 'O\'chirishni tasdiqlash',
          okVariant: 'danger',
          okTitle: 'O\'chirish',
          cancelTitle: 'Bekor qilish',
          hideHeaderClose: false,
          centered: true,
        })

        if (result) {
          await this.$http.delete(`/emlash-category/${item.id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
          await this.fetchCategories()
          this.$bvToast.toast('Kategoriya muvaffaqiyatli o\'chirildi', {
            title: 'Muvaffaqiyat',
            variant: 'success',
          })
        }
      } catch (error) {
        this.$bvToast.toast('Kategoriyani o\'chirishda xatolik yuz berdi', {
          title: 'Xato',
          variant: 'danger',
        })
      }
    },
  },
  watch: {
    selectedCategoryFilter() {
      this.currentPage = 1
      this.fetchEmlashItems()
    },
    currentPage() {
      this.fetchEmlashItems()
    },
  },
}
</script>

<style lang="scss" scoped>
// Asosiy konteyner
.emlash-container {
  background: #f8fafc;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

// Yangi header stillar
.modern-header {
  background: linear-gradient(to right, #7367f0, #9e95f5);
  padding: 1.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
    opacity: 0.1;
  }

  .header-title {
    h3 {
      color: white;
      font-weight: 600;
      font-size: 1.5rem;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .header-actions {
    .btn {
      padding: 0.6rem 1.2rem;
      border-radius: 0.5rem;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      transition: all 0.2s ease;
      background: rgba(255, 255, 255, 0.2);
      border: none;
      backdrop-filter: blur(10px);

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        background: rgba(255, 255, 255, 0.25);
      }

      &:active {
        transform: translateY(0);
      }

      i, svg {
        margin-right: 0.5rem;
      }

      &.btn-primary {
        background: #fff;
        color: #7367f0;

        &:hover {
          background: #f8f8ff;
        }
      }

      &.btn-success {
        background: #28c76f;
        color: #fff;

        &:hover {
          background: #24b263;
        }
      }
    }
  }
}

// Gap utility
.gap-2 {
  gap: 0.5rem;
}

// Jadval stillar
.table {
  border-collapse: separate;
  border-spacing: 0 0.5rem;

  thead {
    th {
      background: #f8f9fa;
      border: none;
      padding: 1rem;
      font-weight: 600;
      color: #5e5873;
      text-transform: uppercase;
      font-size: 0.857rem;
      letter-spacing: 0.5px;

      &:first-child {
        border-radius: 0.5rem 0 0 0.5rem;
      }

      &:last-child {
        border-radius: 0 0.5rem 0.5rem 0;
      }
    }
  }

  tbody {
    tr {
      background: white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      transition: transform 0.2s;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }

      td {
        border: none;
        padding: 1rem;
        vertical-align: middle;
        color: #6e6b7b;

        &:first-child {
          border-radius: 0.5rem 0 0 0.5rem;
        }

        &:last-child {
          border-radius: 0 0.5rem 0.5rem 0;
        }
      }
    }
  }
}

// Tugmalar stillar
.btn {
  padding: 0.786rem 1.5rem;
  border-radius: 0.358rem;
  font-weight: 500;
  line-height: 1;
  transition: all 0.15s ease-in-out;

  &-primary {
    background: linear-gradient(to right, #7367f0, #9e95f5);
    border: none;

    &:hover {
      background: linear-gradient(to right, #6554e8, #8a7ff2);
      transform: translateY(-1px);
      box-shadow: 0 4px 12px rgba(115, 103, 240, 0.4);
    }
  }

  &-success {
    background: linear-gradient(to right, #28c76f, #48da89);
    border: none;

    &:hover {
      background: linear-gradient(to right, #24b263, #40c77c);
      transform: translateY(-1px);
      box-shadow: 0 4px 12px rgba(40, 199, 111, 0.4);
    }
  }

  &-danger {
    background: linear-gradient(to right, #ea5455, #f08182);
    border: none;

    &:hover {
      background: linear-gradient(to right, #e73d3e, #ee6b6c);
      transform: translateY(-1px);
      box-shadow: 0 4px 12px rgba(234, 84, 85, 0.4);
    }
  }
}

// Form elementlari
.form-control {
  border-radius: 0.358rem;
  padding: 0.438rem 1rem;
  border-color: #d8d6de;

  &:focus {
    border-color: #7367f0;
    box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
  }
}

// Select
.custom-select {
  border-radius: 0.358rem;

  &:focus {
    border-color: #7367f0;
    box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
  }
}

// Modal
.modal {
  .modal-content {
    border: none;
    border-radius: 0.358rem;
    box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1);

    .modal-header {
      background: linear-gradient(to right, #7367f0, #9e95f5);
      border-radius: 0.358rem 0.358rem 0 0;
      padding: 1.5rem;

      .modal-title {
        color: white;
        font-weight: 500;
      }

      .close {
        color: white;
        opacity: 1;
        text-shadow: none;
        transition: all 0.15s ease;

        &:hover {
          transform: rotate(90deg);
        }
      }
    }

    .modal-body {
      padding: 1.5rem;
    }

    .modal-footer {
      padding: 1.5rem;
      border-top: 1px solid #ebe9f1;
    }
  }
}

// Pagination
.pagination {
  .page-item {
    margin: 0 0.2rem;

    .page-link {
      border: none;
      min-width: 2.5rem;
      min-height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.358rem;
      font-weight: 500;
      color: #5e5873;

      &:hover {
        background: rgba(115, 103, 240, 0.12);
        color: #7367f0;
      }
    }

    &.active .page-link {
      background: linear-gradient(to right, #7367f0, #9e95f5);
      color: white;
      box-shadow: 0 3px 10px 0 rgba(115, 103, 240, 0.3);
    }
  }
}

// Toast xabarnomalar
.b-toast {
  .toast {
    background: white;
    border: none;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.358rem;

    .toast-header {
      background: transparent;
      border-bottom: 1px solid #ebe9f1;

      strong {
        color: #5e5873;
      }
    }

    .toast-body {
      padding: 1rem;
      color: #6e6b7b;
    }

    &.b-toast-success {
      border-left: 3px solid #28c76f;
    }

    &.b-toast-danger {
      border-left: 3px solid #ea5455;
    }
  }
}

// Animatsiyalar
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-enter-active {
  animation: fadeIn 0.3s ease-out;
}

// Kategoriyalar jadvali uchun qo'shimcha stillar
.table {
  &.categories-table {
    margin-bottom: 2rem;

    th {
      background: #f8f9fa;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.857rem;
    }

    td {
      vertical-align: middle;
    }
  }
}

// Switch uchun qo'shimcha stillar
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fff;
  border-color: #fff;
}

.custom-switch .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #7367f0;
}

// Yangi switch stillar
.switch-container {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  backdrop-filter: blur(10px);
  margin-right: 1rem;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }

  .switch-label {
    color: white;
    font-weight: 500;
    margin-right: 1rem;
    font-size: 0.95rem;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .switch-wrapper {
    margin: 0;

    .custom-control {
      min-height: auto;
      padding-left: 2.5rem;

      .custom-control-label {
        &::before {
          height: 1.4rem;
          width: 2.5rem;
          border-radius: 1rem;
          background: rgba(255, 255, 255, 0.2);
          border: 2px solid rgba(255, 255, 255, 0.5);
          transition: all 0.3s ease;
        }

        &::after {
          top: calc(0.2rem);
          left: calc(-2.3rem);
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background-color: white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out;
        }
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        background: #28c76f;
        border-color: #28c76f;
      }

      &::after {
        transform: translateX(1.1rem);
        background-color: white;
      }
    }

    .custom-control-input:focus ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(40, 199, 111, 0.25);
    }
  }
}

// Badge stillar
.badge {
  padding: 0.5rem 0.8rem;
  font-size: 0.9rem;
  font-weight: 500;

  &.badge-success {
    background-color: #28c76f;
    color: white;
  }

  &.badge-danger {
    background-color: #ea5455;
    color: white;
  }
}
</style>
