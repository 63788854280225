<template>
  <content-with-sidebar class="blog-wrapper" v-loading="loading">
    <!-- Statistika qismi -->
    <b-card class="mb-2">
      <b-row>
        <b-col
          v-for="(stat, index) in categoryStats"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          lg="2"
        >
          <div class="stat-card" :class="tagsColor(stat.type)">
            <div class="stat-icon">
              <feather-icon :icon="getCategoryIcon(stat.type)" />
            </div>
            <div class="stat-info">
              <h3 class="stat-count">{{ stat.count }}</h3>
              <p class="stat-title">{{ getCategoryName(stat.type) }}</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card-body>
      <div class="blog-list-wrapper">
        <!-- Card component -->
        <b-card
          v-for="blog in paginatedBlogs"
          :key="blog.hashId"
          tag="article"
          no-body
          class="custom-card"
        >
          <div class="card-img-wrapper">
            <b-link :to="{ name: 'advice-blog-detail', params: { id: blog.id } }">
              <b-img
                :src="`https://bolajon.mtsh.uz/api/file-storages/preview/${blog.hashId}/avatar.jpg`"
                class="card-img-top"
                :alt="blog.titleUz"
              />
              <div class="overlay">
                <feather-icon icon="EyeIcon" size="16" />
              </div>
            </b-link>
          </div>

          <b-card-body>
            <div class="category-badge" :class="tagsColor(blog.type)">
              <span v-if="blog.type === 'UYNASH'">O'ynash</span>
              <span v-if="blog.type === 'XAVF'">Xavfsizlik</span>
              <span v-if="blog.type === 'OTAONA'">Ota-ona</span>
              <span v-if="blog.type === 'SALOMATLIK'">Salomatlik</span>
              <span v-if="blog.type === 'SEZGIR'">Tarbiya</span>
              <span v-if="blog.type === 'OVQAT'">Ovqatlanish</span>
            </div>

            <h3 class="blog-title">
              <b-link :to="{ name: 'advice-blog-detail', params: { id: blog.id } }">
                {{ blog.titleUz }}
              </b-link>
            </h3>

            <b-link
              :to="{ name: 'advice-blog-detail', params: { id: blog.id } }"
              class="read-more"
            >
              <span>Batafsil</span>
              <feather-icon icon="ArrowRightIcon" size="12" />
            </b-link>
          </b-card-body>
        </b-card>
      </div>

      <!-- Pagination -->
      <div class="mt-2 d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="computedTotalRows"
          :per-page="perPage"
          align="center"
        />
      </div>
    </b-card-body>

    <!-- Sidebar -->
    <div slot="sidebar" class="blog-sidebar">
      <!-- Yangi maslahat qo'shish tugmasi -->
      <b-button 
        to="/advice" 
        variant="primary"
        class="add-advice-btn mb-2"
      >
        <feather-icon icon="PlusCircleIcon" />
        <span>Yangi maslahat qo'shish</span>
      </b-button>

      <!-- Kategoriyalar -->
      <div class="blog-categories">
        <h6 class="section-label">Kategoriyalar</h6>
        <div 
          v-for="category in adviceCategoryTypes" 
          :key="category.value" 
          class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-link 
            @click="selectCategory(category)"
            class="category-link"
            :class="{ 'active': isActiveCategory(category.value) }"
          >
            <b-avatar 
              rounded 
              size="32" 
              :variant="tagsColor(category.value)" 
              class="mr-75"
            >
              <feather-icon :icon="category.icon" size="16" />
            </b-avatar>
            <div class="blog-category-title text-body">
              {{ category.text }}
            </div>
          </b-link>
        </div>
      </div>
    </div>
  </content-with-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BButton
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import spinner from '@/views/components/spinner/Spinner.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    spinner,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
  },
  data() {
    return {
      loading: false,
      search_query: '',
      blogList: [],
      adviceCategoryTypes: [
        { value: 'UYNASH', text: "O'ynash va o'rganish", icon: 'RssIcon' },
        { value: 'XAVF', text: 'Xavfsizlik va himoya', icon: 'ShieldIcon' },
        { value: 'OTAONA', text: "Ota-onalar burchagi", icon: 'UserCheckIcon' },
        { value: 'SALOMATLIK', text: 'Salomatlik va farovonlik', icon: 'HeartIcon' },
        { value: 'SEZGIR', text: "Sezgir tarbiya va g'amxo'rlik", icon: 'FeatherIcon' },
        { value: 'OVQAT', text: "Ovqatlantirish va ko'krak suti bilan emizish", icon: 'ListIcon' },
        { value: null, text: 'Barchasi', icon: 'RefreshCcwIcon' },
      ],
      selectedCategory: null,
      currentPage: 1,
      perPage: 12,
      totalRows: 0,
      adviceBlog: {
        ageCategoryId: null,
        type: null,
      },
    }
  },
  watch: {
    currentPage() {
      this.getcategory()
    }
  },
  created() {
    this.getcategory()
  },
  computed: {
    filteredBlogs() {
      let filtered = [...this.blogList]

      // Agar kategoriya tanlangan bo'lsa
      if (this.selectedCategory) {
        filtered = filtered.filter(blog => blog.type === this.selectedCategory)
      }

      // Agar qidiruv so'rovi kiritilgan bo'lsa
      if (this.search_query) {
        const searchLower = this.search_query.toLowerCase()
        filtered = filtered.filter(blog => 
          blog.titleUz.toLowerCase().includes(searchLower)
        )
      }

      return filtered
    },

    // Pagination uchun
    paginatedBlogs() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.filteredBlogs.slice(start, end)
    },

    // Total rows uchun
    computedTotalRows() {
      return this.filteredBlogs.length
    },

    categoryStats() {
      return this.adviceCategoryTypes
        .filter(cat => cat.value) // null va undefined qiymatlarni filterlash
        .map(category => ({
          type: category.value,
          count: this.blogList.filter(blog => blog.type === category.value).length,
        }))
    }
  },
  methods: {
    selectCategory(category) {
      // Agar tanlangan kategoriya qayta bosilsa, filterni olib tashlash
      if (this.selectedCategory === category.value) {
        this.selectedCategory = null
      } else {
        this.selectedCategory = category.value
      }
      
      // Sahifani 1-ga qaytarish
      this.currentPage = 1
    },
    getcategory() {
      this.loading = true
      this.$http
        .post(`/advice/find-all`, {
          ageCategoryId: null,
          type: null
        })
        .then(response => {
          this.blogList = response.data.content
          this.loading = false
        })
        .catch(error => {
          console.error('Axios error:', error)
          this.loading = false
        })
    },
    tagsColor(tag) {
      switch (tag) {
        case 'UYNASH':
          return 'light-primary'
        case 'SALOMATLIK':
          return 'light-danger'
        case 'XAVF':
          return 'light-secondary'
        case 'OTAONA':
          return 'light-success'
        case 'SEZGIR':
          return 'light-success'
        case 'OVQAT':
          return 'light-secondary'
        default:
          return 'light-primary'
      }
    },
    isActiveCategory(categoryValue) {
      return this.selectedCategory === categoryValue
    },
    getCategoryIcon(type) {
      const category = this.adviceCategoryTypes.find(cat => cat.value === type)
      return category ? category.icon : 'HelpCircleIcon'
    },
    getCategoryName(type) {
      switch (type) {
        case 'UYNASH': return "O'ynash"
        case 'XAVF': return 'Xavfsizlik'
        case 'OTAONA': return 'Ota-ona'
        case 'SALOMATLIK': return 'Salomatlik'
        case 'SEZGIR': return 'Tarbiya'
        case 'OVQAT': return 'Ovqatlanish'
        default: return type
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-blog.scss';
.blog-list-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  padding: 1rem;
}

.custom-card {
  position: relative;
  border: none;
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  height: 280px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);

    .card-img-top {
      transform: scale(1.15);
    }

    .overlay {
      opacity: 1;
      backdrop-filter: blur(3px);
    }

    .read-more {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .card-img-wrapper {
    position: relative;
    overflow: hidden;
    height: 160px;

    .card-img-top {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.7s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(115, 103, 240, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: all 0.4s ease;

      svg {
        color: white;
        height: 24px;
        width: 24px;
        filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
      }
    }
  }

  .card-body {
    padding: 1.2rem;
    position: relative;
    height: 120px;
    display: flex;
    flex-direction: column;
  }

  .category-badge {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.4rem 1rem;
    border-radius: 20px;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.2);

    &.light-primary {
      background: rgba(115, 103, 240, 0.9);
      color: white;
    }
    &.light-success {
      background: rgba(40, 199, 111, 0.9);
      color: white;
    }
    &.light-danger {
      background: rgba(234, 84, 85, 0.9);
      color: white;
    }
    &.light-secondary {
      background: rgba(108, 117, 125, 0.9);
      color: white;
    }
  }

  .blog-title {
    font-size: 1rem;
    margin: 1rem 0 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    height: 3em;
    font-weight: 600;

    a {
      color: #2c2c2c;
      transition: color 0.3s ease;

      &:hover {
        color: #7367f0;
        text-decoration: none;
      }
    }
  }

  .read-more {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7367f0;
    font-weight: 600;
    font-size: 0.85rem;
    margin-top: auto;
    padding: 0.5rem;
    border-radius: 8px;
    background: rgba(115, 103, 240, 0.08);
    transition: all 0.3s ease;

    span {
      margin-right: 5px;
    }

    svg {
      height: 16px;
      transition: transform 0.3s ease;
    }

    &:hover {
      text-decoration: none;
      background: rgba(115, 103, 240, 0.12);
      
      svg {
        transform: translateX(5px);
      }
    }
  }
}

// Responsive dizayn
@media (min-width: 1400px) {
  .blog-list-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1399px) and (min-width: 992px) {
  .blog-list-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .blog-list-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .custom-card {
    height: 300px;
  }
}

@media (max-width: 767px) {
  .blog-list-wrapper {
    grid-template-columns: 1fr;
  }
  .custom-card {
    height: 320px;
  }
}

// Kategoriya tugmasi stili
.category-link {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  width: 100%;

  &:hover {
    background: rgba(115, 103, 240, 0.05);
    text-decoration: none;
  }

  &.active {
    background: rgba(115, 103, 240, 0.1);
    
    .blog-category-title {
      color: #7367f0;
      font-weight: 600;
    }

    .b-avatar {
      transform: scale(1.1);
    }
  }

  .blog-category-title {
    transition: color 0.3s ease;
  }
}

// Loading animatsiyasi
.blog-wrapper {
  position: relative;

  &.loading {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      z-index: 1;
    }
  }
}

.stat-card {
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s ease;
  margin-bottom: 0.5rem;

  &:hover {
    transform: translateY(-2px);
  }

  &.light-primary {
    background: rgba(115, 103, 240, 0.1);
    .stat-icon, .stat-count { color: #7367f0; }
  }

  &.light-success {
    background: rgba(40, 199, 111, 0.1);
    .stat-icon, .stat-count { color: #28c76f; }
  }

  &.light-danger {
    background: rgba(234, 84, 85, 0.1);
    .stat-icon, .stat-count { color: #ea5455; }
  }

  &.light-secondary {
    background: rgba(108, 117, 125, 0.1);
    .stat-icon, .stat-count { color: #6c757d; }
  }

  .stat-icon {
    padding: 0.5rem;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.5);

    svg {
      height: 1.2rem;
      width: 1.2rem;
    }
  }

  .stat-info {
    flex: 1;

    .stat-count {
      font-size: 1.2rem;
      font-weight: 600;
      margin: 0;
      line-height: 1.2;
    }

    .stat-title {
      font-size: 0.8rem;
      margin: 0;
      opacity: 0.8;
    }
  }
}

// Responsive
@media (max-width: 1200px) {
  .stat-card {
    padding: 0.8rem;
    
    .stat-info {
      .stat-count {
        font-size: 1rem;
      }
      .stat-title {
        font-size: 0.75rem;
      }
    }
  }
}

// Sidebar stilini yangilash
.blog-sidebar {
  .blog-categories {
    background: white;
    border-radius: 8px;
    padding: 1.2rem;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    
    .section-label {
      color: #5e5873;
      font-size: 1.1rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      margin-bottom: 1.2rem;
    }
  }
}

// Yangi maslahat qo'shish tugmasi stili
.add-advice-btn {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  
  svg {
    height: 16px;
    width: 16px;
  }
}
</style>

