<template>
  <div>
    <!-- Header -->
    <b-card class="header-card">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h2 class="font-weight-bolder mb-1">
            <feather-icon 
              icon="LayersIcon" 
              class="mr-50" 
              size="24"
              style="color: #7367f0;"
            />
            Kategoriyalar
          </h2>
          <p class="mb-0 text-muted">
            <feather-icon 
              icon="InfoIcon" 
              size="16" 
              class="mr-50"
            />
            Bolalar yosh kategoriyalarini boshqarish
          </p>
        </div>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="add-new-button d-flex align-items-center"
            @click="showModal = true"
          >
            <feather-icon 
              icon="PlusIcon" 
              size="16"
              class="mr-50"
            />
            <span>Yangi qo'shish</span>
          </b-button>
    
        </div>
      </div>
    </b-card>

    <!-- Search and Filters -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group>
              <label>Qidirish:</label>
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Kategoriya bo'yicha qidirish..."
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group>
              <label>Turi bo'yicha filtrlash:</label>
              <v-select
                v-model="selectedType"
                :options="ageCategoryTypes"
                label="text"
                :reduce="option => option.value"
                placeholder="Barchasi"
                clearable
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <b-table
        responsive
        :items="filteredItems"
        :fields="fields"
        class="position-relative"
        :busy="isLoading"
        show-empty
        empty-text="Kategoriyalar topilmadi"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
            <strong>Ma'lumotlar yuklanmoqda...</strong>
          </div>
        </template>

        <template #cell(type)="data">
          <b-badge
            pill
            :variant="getCategoryTypeVariant(data.item.type)"
          >
            {{ getCategoryTypeLabel(data.item.type) }}
          </b-badge>
        </template>

        <template #cell(range)="data">
          <span class="text-nowrap">
            {{ data.item.startPoint }} - {{ data.item.endPoint }}
            {{ data.item.type === 'MONTH' ? 'oy' : 'yil' }}
          </span>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex justify-content-end align-items-center">
            <b-button
              variant="outline-primary"
              size="sm"
              class="mr-1"
              v-b-tooltip.hover.top
              title="Tahrirlash"
              @click="openEditCategory(data.item.id)"
            >
              <feather-icon icon="EditIcon" size="16"/>
            </b-button>
            <b-button
              variant="outline-danger"
              size="sm"
              v-b-tooltip.hover.top
              title="O'chirish"
              @click="confirmDelete(data.item.id)"
            >
              <feather-icon icon="TrashIcon" size="16"/>
            </b-button>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Jami: {{ totalRows }} ta</span>
          </b-col>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="mb-0 pagination-primary"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Modal -->
    <b-modal
      v-model="showModal"
      :title="isEditing ? 'Kategoriyani tahrirlash' : 'Yangi kategoriya'"
      centered
      size="lg"
      @hidden="resetForm"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Kategoriya turi"
              label-for="type"
              :state="categoryTypeState"
              invalid-feedback="Kategoriya turini tanlang"
            >
              <v-select
                v-model="category.type"
                :options="ageCategoryTypes"
                label="text"
                :reduce="option => option.value"
                :clearable="false"
                :state="categoryTypeState"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Bola yoshi / dan"
              label-for="startPoint"
              :state="startPointState"
              invalid-feedback="Boshlang'ich yosh kiritilishi shart"
            >
              <b-form-input
                id="startPoint"
                v-model.number="category.startPoint"
                type="number"
                min="0"
                :state="startPointState"
                placeholder="0"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Bola yoshi / gacha (ixtiyoriy)"
              label-for="endPoint"
              :state="endPointState"
              invalid-feedback="Yakuniy yosh boshlang'ich yoshdan katta bo'lishi kerak"
            >
              <b-form-input
                id="endPoint"
                v-model.number="category.endPoint"
                type="number"
                :min="category.startPoint + 1"
                :state="endPointState"
                placeholder="Kiritilmasa cheklanmagan"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          @click="showModal = false"
        >
          Bekor qilish
        </b-button>
        <b-button
          variant="primary"
          :disabled="!isFormValid"
          @click="submitCategoryForm"
        >
          <span class="mr-50">{{ isEditing ? 'Saqlash' : "Qo'shish" }}</span>
          <feather-icon
            icon="SaveIcon"
            class="mr-0"
          />
        </b-button>
      </template>
    </b-modal>

    <!-- Delete Confirmation Modal -->
    <b-modal
      v-model="showDeleteModal"
      title="Kategoriyani o'chirish"
      centered
      ok-variant="danger"
      ok-title="O'chirish"
      cancel-title="Bekor qilish"
      @ok="deleteCategory"
    >
      <div class="d-flex align-items-center flex-column">
        <feather-icon
          icon="AlertCircleIcon"
          size="48"
          class="text-danger mb-2"
        />
        <h4>Kategoriyani o'chirishni tasdiqlaysizmi?</h4>
        <p class="text-center">
          Bu amalni ortga qaytarib bo'lmaydi. Kategoriyani o'chirishdan oldin barcha bog'liq ma'lumotlarni tekshiring.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  PlusCircleIcon,
  EditIcon,
  TrashIcon,
  LayersIcon,
  InfoIcon,
  SearchIcon,
  AlertCircleIcon,
  CheckIcon,
  AlertTriangleIcon,
  SaveIcon,
} from 'vue-feather-icons'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
    BSpinner,
    BDropdown,
    BDropdownItem,
    vSelect,
    PlusCircleIcon,
    EditIcon,
    TrashIcon,
    LayersIcon,
    InfoIcon,
    SearchIcon,
    AlertCircleIcon,
    CheckIcon,
    AlertTriangleIcon,
    SaveIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showModal: false,
      showDeleteModal: false,
      isEditing: false,
      isLoading: false,
      selectedId: null,
      searchQuery: '',
      selectedType: null,
      category: this.getInitialCategoryState(),
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'type', label: 'Turi' },
        { key: 'range', label: 'Yosh oralig\'i' },
        { 
          key: 'actions',
          label: 'Amallar',
          tdClass: 'text-right',
          thClass: 'text-right',
        },
      ],
      ageCategoryTypes: [
        { value: 'MONTH', text: 'Oy' },
        { value: 'YEAR', text: 'Yil' },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      items: [],
    }
  },
  computed: {
    filteredItems() {
      let filtered = [...this.items]
      
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase()
        filtered = filtered.filter(item => 
          item.type.toLowerCase().includes(query) ||
          item.startPoint.toString().includes(query) ||
          item.endPoint.toString().includes(query)
        )
      }

      if (this.selectedType) {
        filtered = filtered.filter(item => item.type === this.selectedType)
      }

      return filtered
    },
    isFormValid() {
      return this.categoryTypeState && this.startPointState && this.endPointState
    },
    categoryTypeState() {
      return !!this.category.type
    },
    startPointState() {
      return this.category.startPoint !== null && this.category.startPoint >= 0
    },
    endPointState() {
      if (this.category.endPoint === null) return true
      return this.category.endPoint > this.category.startPoint
    },
  },
  watch: {
    currentPage() {
      this.fetchCategories()
    },
  },
  created() {
    this.fetchCategories()
  },
  methods: {
    getInitialCategoryState() {
      return {
        id: null,
        type: '',
        startPoint: null,
        endPoint: null,
      }
    },
    getCategoryTypeVariant(type) {
      return type === 'MONTH' ? 'light-primary' : 'light-success'
    },
    getCategoryTypeLabel(type) {
      return type === 'MONTH' ? 'Oy' : 'Yil'
    },
    async fetchCategories() {
      this.isLoading = true
      try {
        const currentPage = this.currentPage - 1
        const response = await this.$http.post(
          `/age-category/find-all?page=${currentPage}&size=${this.perPage}`
        )
        this.totalRows = response.data.totalElements
        this.items = response.data.content
      } catch (error) {
        this.showErrorToast("Ma'lumotlarni yuklashda xatolik yuz berdi")
      } finally {
        this.isLoading = false
      }
    },
    openEditCategory(id) {
      const categoryToEdit = this.items.find(category => category.id === id)
      this.category = { ...categoryToEdit }
      this.isEditing = true
      this.showModal = true
    },
    confirmDelete(id) {
      this.selectedId = id
      this.showDeleteModal = true
    },
    async deleteCategory() {
      try {
        // API call implementation here
        await this.$http.delete(`/age-category/${this.selectedId}`)
        this.showSuccessToast("Kategoriya muvaffaqiyatli o'chirildi")
        this.fetchCategories()
      } catch (error) {
        this.showErrorToast("O'chirishda xatolik yuz berdi")
      }
    },
    resetForm() {
      this.category = this.getInitialCategoryState()
      this.isEditing = false
    },
    async submitCategoryForm() {
      try {
        await this.$http.post('/age-category/save', this.category)
        this.showSuccessToast(
          this.isEditing
            ? 'Kategoriya muvaffaqiyatli yangilandi'
            : "Yangi kategoriya muvaffaqiyatli qo'shildi"
        )
        this.showModal = false
        this.fetchCategories()
      } catch (error) {
        this.showErrorToast("Ma'lumotni saqlashda xatolik yuz berdi")
      }
    },
    showSuccessToast(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Muvaffaqiyatli',
          icon: 'CheckIcon',
          variant: 'success',
          text,
        },
      })
    },
    showErrorToast(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Xatolik',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.add-new-button {
  padding: 0.786rem 1.5rem;
  background-color: #7367f0;
  border-color: #7367f0;
  box-shadow: 0 4px 8px rgba(115, 103, 240, 0.4);
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(115, 103, 240, 0.5);
  }
  
  svg {
    margin-right: 0.5rem;
    height: 16px;
    width: 16px;
  }
}

h2 {
  display: flex;
  align-items: center;
  color: #5e5873;
  font-size: 1.5rem;
}

.text-muted {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.card-company-table {
  ::v-deep td {
    vertical-align: middle;
    padding: 0.75rem;
    
    .b-avatar {
      margin-right: 0.75rem;
    }
  }
}

.dropdown-icon-wrapper {
  .btn {
    padding: 0.5rem;
    &:hover {
      transform: translateY(-2px);
    }
  }
}

.pagination-primary {
  ::v-deep .page-item {
    &.active .page-link {
      background-color: #7367f0 !important;
    }
    .page-link:hover {
      color: #7367f0;
    }
  }
}

.btn-icon {
  width: 34px;
  height: 34px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.15s ease, background 0.15s ease;

  &:hover {
    transform: translateY(-2px);
  }

  &.btn-flat-primary {
    &:hover {
      background: rgba(115, 103, 240, 0.2);
    }
  }

  &.btn-flat-danger {
    &:hover {
      background: rgba(234, 84, 85, 0.2);
    }
  }

  svg {
    stroke-width: 2px;
  }
}

::v-deep td.text-right {
  padding-right: 1.5rem !important;
}

::v-deep th.text-right {
  padding-right: 1.5rem !important;
}

.header-card {
  background: linear-gradient(to right, #7367f0, #9e95f5);
  
  h2 {
    color: white;
  }
  
  .text-muted {
    color: rgba(255, 255, 255, 0.8) !important;
  }
  
  .add-new-button {
    background-color: white;
    color: #7367f0;
    border: none;
    
    &:hover {
      background-color: #f8f8f8;
      transform: translateY(-2px);
    }
    
    svg {
      color: inherit;
    }
  }
}
</style>
