<template>
  <div class="modern-loading-overlay">
    <div class="modern-loading-content">
      <div class="cyber-loader">
        <div class="cyber-circle"></div>
        <div class="cyber-circle"></div>
        <div class="cyber-circle"></div>
        <div class="cyber-circle"></div>
        <div class="cyber-core"></div>
        <div class="cyber-line line-1"></div>
        <div class="cyber-line line-2"></div>
        <div class="cyber-line line-3"></div>
        <div class="cyber-line line-4"></div>
      </div>
      <div class="neon-text">
        <span data-text="M">M</span>
        <span data-text="A">A</span>
        <span data-text="'">&#39;</span>
        <span data-text="L">L</span>
        <span data-text="U">U</span>
        <span data-text="M">M</span>
        <span data-text="O">O</span>
        <span data-text="T">T</span>
        <span data-text="L">L</span>
        <span data-text="A">A</span>
        <span data-text="R">R</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CyberLoading'
}
</script>

<style lang="scss" scoped>
.modern-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 80vh;
  background: rgba(14, 14, 22, 0.97);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, 
      rgba(115, 103, 240, 0.1),
      rgba(40, 199, 111, 0.1),
      rgba(234, 84, 85, 0.1),
      rgba(255, 159, 67, 0.1)
    );
    filter: blur(20px);
    z-index: -1;
  }
}

.modern-loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.cyber-loader {
  position: relative;
  width: 150px;
  height: 150px;
  perspective: 1000px;
  transform-style: preserve-3d;

  &:hover {
    .cyber-core {
      animation: pulseCore 1s ease-in-out infinite;
    }
    
    .cyber-circle {
      animation-duration: 2s;
    }
  }
}

.cyber-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-radius: 50%;
  animation: cyberRotate 3s linear infinite;

  &:nth-child(1) {
    border-top-color: #7367f0;
    animation-delay: 0s;
  }
  &:nth-child(2) {
    border-right-color: #28c76f;
    animation-delay: 0.5s;
  }
  &:nth-child(3) {
    border-bottom-color: #ea5455;
    animation-delay: 1s;
  }
  &:nth-child(4) {
    border-left-color: #ff9f43;
    animation-delay: 1.5s;
  }
}

.cyber-core {
  position: absolute;
  width: 30%;
  height: 30%;
  background: radial-gradient(circle, #7367f0, #4839e5);
  border-radius: 50%;
  left: 35%;
  top: 35%;
  animation: pulseCore 2s ease-in-out infinite;
  box-shadow: 0 0 20px #7367f0;
}

.cyber-line {
  position: absolute;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #7367f0, transparent);
  animation: cyberScan 2s linear infinite;

  &.line-1 { top: 25%; transform: rotate(0deg); animation-delay: 0s; }
  &.line-2 { top: 75%; transform: rotate(0deg); animation-delay: 0.5s; }
  &.line-3 { left: 25%; width: 2px; height: 100%; animation-delay: 1s; }
  &.line-4 { left: 75%; width: 2px; height: 100%; animation-delay: 1.5s; }
}

.neon-text {
  display: flex;
  gap: 8px;
  
  span {
    position: relative;
    display: inline-block;
    font-size: 2rem;
    font-weight: bold;
    color: #7367f0;
    text-shadow: 0 0 10px #7367f0,
                 0 0 20px #7367f0,
                 0 0 40px #7367f0;
    animation: neonGlow 2s ease-in-out infinite;

    &::before {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      filter: blur(15px);
      opacity: 0.5;
      color: #7367f0;
    }

    @for $i from 1 through 11 {
      &:nth-child(#{$i}) {
        animation-delay: #{$i * 0.1}s;
      }
    }
  }
}

@keyframes cyberRotate {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
}

@keyframes cyberScan {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes pulseCore {
  0%, 100% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
}

@keyframes neonGlow {
  0%, 100% {
    filter: brightness(1);
    transform: scale(1) translateY(0);
  }
  50% {
    filter: brightness(1.5);
    transform: scale(1.1) translateY(-5px);
  }
}

@media (max-width: 768px) {
  .cyber-loader {
    width: 120px;
    height: 120px;
  }

  .neon-text span {
    font-size: 1.5rem;
  }
}
</style>
