<template>
  <div>
    <!-- Statistika kartlari -->
    <b-row>
      <b-col xl="3" md="6" sm="12">
        <b-card class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h2 class="font-weight-bolder mb-1">{{ statistics.last24Hours }}</h2>
              <p class="card-text">So'nggi 24 soat</p>
            </div>
            <div class="avatar bg-light-success p-50">
              <feather-icon
                icon="ClockIcon"
                size="32"
                class="text-success"
              />
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col xl="3" md="6" sm="12">
        <b-card class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h2 class="font-weight-bolder mb-1">{{ statistics.totalRequests }}</h2>
              <p class="card-text">Jami so'rovlar</p>
            </div>
            <div class="avatar bg-light-primary p-50">
              <feather-icon
                icon="ActivityIcon"
                size="32"
                class="text-primary"
              />
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col xl="3" md="6" sm="12">
        <b-card class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h2 class="font-weight-bolder mb-1">{{ topIPsCount }}</h2>
              <p class="card-text">Faol IP manzillar</p>
            </div>
            <div class="avatar bg-light-warning p-50">
              <feather-icon
                icon="UsersIcon"
                size="32"
                class="text-warning"
              />
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col xl="3" md="6" sm="12">
        <b-card class="mb-3">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h2 class="font-weight-bolder mb-1">{{ statistics.suspiciousIPs }}</h2>
              <p class="card-text">Xavfli IP manzillar</p>
            </div>
            <div class="avatar bg-light-danger p-50">
              <feather-icon
                icon="AlertTriangleIcon"
                size="32"
                class="text-danger"
              />
            </div>
          </div>
          <small class="text-muted mt-1" v-if="statistics.suspiciousIPs > 0">
            <feather-icon
              icon="InfoIcon"
              size="12"
              class="text-danger mr-1"
            />
            Ko'p so'rovlar yuborgan yoki shubhali faoliyat aniqlangan
          </small>
        </b-card>
      </b-col>
    </b-row>

    <!-- Top IP manzillar -->
    <b-row>
      <b-col lg="6" class='pb-0'>
        <b-card style='height: 94%'  title="Eng faol IP manzillar" >
          <b-table
            :items="statistics.topIPs"
            :fields="topIPFields"
            striped
            responsive
          >
            <template #cell(ipAddress)="data">
              <span class="text-primary">{{ data.item.ipAddress }}</span>
            </template>
            <template #cell(requestCount)="data">
              <b-badge variant="light-primary" pill>
                {{ data.item.requestCount }}
              </b-badge>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <!-- So'rovlar grafigi -->
      <b-col lg="6">
        <b-card title="So'rovlar statistikasi">
          <vue-apex-charts
            type="bar"
            height="350"
            :options="chartOptions"
            :series="chartSeries"
          />
        </b-card>
      </b-col>
    </b-row>

    <!-- Batafsil ma'lumotlar -->
    <b-row>
      <b-col cols="12">
        <b-card title="Batafsil ma'lumotlar">
          <b-table
            :items="groupedLogs"
            :fields="groupedFields"
            :per-page="perPage"
            :current-page="currentPage"
            striped
            responsive
          >
            <template #cell(computerName)="data">
              <div class="d-flex align-items-center">
                <feather-icon icon="MonitorIcon" class="mr-1" />
                <span>{{ data.item.computerName }}</span>
              </div>
            </template>
            <template #cell(lastAccess)="data">
              {{ formatDateTime(data.item.lastAccess) }}
            </template>
            <template #cell(totalRequests)="data">
              <b-badge variant="light-primary" pill>
                {{ data.item.totalRequests }}
              </b-badge>
            </template>
            <template #cell(details)="data">
              <b-button
                size="sm"
                variant="primary"
                @click="showRequestDetails(data.item)"
              >
                Ko'rish
              </b-button>
            </template>
            <template #cell(browser)="data">
              <div class="d-flex align-items-center">
                <img
                  :src="getBrowserImage(data.item.userAgent)"
                  :alt="getBrowserName(data.item.userAgent)"
                  class="browser-icon mr-1"
                />
                <span>{{ getBrowserName(data.item.userAgent) }}</span>
              </div>
            </template>
          </b-table>

          <!-- Pagination qismini yangilaymiz -->
          <div class="mx-2 mt-2">
            <b-row>
              <b-col cols="12" class="d-flex align-items-center justify-content-between">
                <!-- Sahifa ma'lumoti -->
                <div class="text-muted">
                  Sahifa {{ currentPage }} / {{ totalPages }}
                </div>

                <!-- Pagination knopkalari -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-text="«"
                  prev-text="‹"
                  next-text="›"
                  last-text="»"
                  class="mb-0"
                  align="right"
                >
                  <template #first-text><feather-icon icon="ChevronsLeftIcon" size="18"/></template>
                  <template #prev-text><feather-icon icon="ChevronLeftIcon" size="18"/></template>
                  <template #next-text><feather-icon icon="ChevronRightIcon" size="18"/></template>
                  <template #last-text><feather-icon icon="ChevronsRightIcon" size="18"/></template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- Row details o'rniga modal qo'shamiz -->
    <b-modal

      v-model="showModal"
      :title="getModalTitle"
      size="lg"
      scrollable

    >
      <b-table
        v-if="selectedLog"
        :items="selectedLog.requests"
        :fields="requestFields"
        striped
        hover
        responsive
      >
        <template #cell(time)="data">
          {{ formatDateTime(data.item.time) }}
        </template>
        <template #cell(method)="data">
          <b-badge :variant="getMethodVariant(data.item.method)">
            {{ data.item.method }}
          </b-badge>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="getStatusVariant(data.item.status)">
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(browser)="data">
          <div class="d-flex align-items-center">
            <img
              :src="getBrowserImage(data.item.userAgent)"
              :alt="getBrowserName(data.item.userAgent)"
              class="browser-icon mr-1"
            />
            <span>{{ getBrowserName(data.item.userAgent) }}</span>
          </div>
        </template>
      </b-table>
    </b-modal>

    <!-- So'rovlar taqsimoti -->
    <b-row class="mt-2">
      <b-col cols="12">
        <b-card title="So'rovlar taqsimoti">
          <b-row>
            <b-col md="4">
              <h5 class="text-center mb-2">So'rov metodlari</h5>
              <vue-apex-charts
                type="pie"
                height="300"
                :options="methodChartOptions"
                :series="methodChartSeries"
              />
            </b-col>
            <b-col md="4">
              <h5 class="text-center mb-2">Status kodlari</h5>
              <vue-apex-charts
                type="pie"
                height="300"
                :options="statusChartOptions"
                :series="statusChartSeries"
              />
            </b-col>
            <b-col md="4">
              <h5 class="text-center mb-2">Brauzerlar</h5>
              <vue-apex-charts
                type="pie"
                height="300"
                :options="browserChartOptions"
                :series="browserChartSeries"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- Xavfli IP manzillar jadvali -->
    <b-row class="mt-2">
      <b-col cols="12">
        <b-card title="Xavfli IP manzillar">
          <b-table
            :items="suspiciousIPs"
            :fields="suspiciousIPFields"
            striped
            responsive
          >
            <template #cell(ipAddress)="data">
              <div class="d-flex align-items-center">
                <feather-icon icon="AlertTriangleIcon" size="16" class="text-danger mr-1"/>
                <span class="text-danger">{{ data.item.ipAddress }}</span>
              </div>
            </template>

            <template #cell(requestCount)="data">
              <b-badge variant="light-danger" pill>
                {{ data.item.requestCount }}
              </b-badge>
            </template>

            <template #cell(lastAccess)="data">
              {{ formatDateTime(data.item.lastAccess) }}
            </template>

            <template #cell(reason)="data">
              <div>
                <b-badge
                  v-for="(reason, index) in data.item.reasons"
                  :key="index"
                  variant="danger"
                  class="mr-1"
                >
                  {{ reason }}
                </b-badge>
              </div>
            </template>

            <template #cell(actions)="data">
              <b-button
                size="sm"
                variant="danger"
                @click="showSuspiciousDetails(data.item)"
              >
                Batafsil
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BBadge, BButton, BModal, BPagination, BCardText, BProgress
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import axios from '@/libs/axios'

export default {
  components: {
    FeatherIcon,
    BRow,
    BCol,
    BCard,
    BTable,
    BBadge,
    BButton,
    BModal,
    VueApexCharts,
    BPagination,
    BCardText,
    BProgress,
  },
  data() {
    return {
      statistics: {
        uniqueIPs: 0,
        last24Hours: 0,
        totalRequests: 0,
        topIPs: [],
      },
      groupedLogs: [],
      topIPFields: [
        { key: 'ipAddress', label: 'IP Manzil' },
        { key: 'requestCount', label: "So'rovlar soni" },
      ],
      groupedFields: [
        { key: 'computerName', label: 'Kompyuter nomi' },
        { key: 'ipAddress', label: 'IP Manzil' },
        { key: 'browser', label: 'Brauzer' },
        { key: 'lastAccess', label: "So'nggi faollik" },
        { key: 'totalRequests', label: "So'rovlar soni" },
        { key: 'details', label: 'Batafsil' },
      ],
      requestFields: [
        { key: 'method', label: 'Metod' },
        { key: 'time', label: 'Vaqt' },
        { key: 'uri', label: 'URI' },
        { key: 'processingTime', label: 'Bajarilish vaqti (ms)' },
        { key: 'status', label: 'Status' },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "So'rovlar soni",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter(val) {
              return `${val} so'rov`
            },
          },
        },
      },
      chartSeries: [
        {
          name: "So'rovlar",
          data: [],
        },
      ],
      showModal: false,
      selectedLog: null,
      browserImages: {
        Chrome: require('@/assets/images/browsers/chrome.png'),
        Firefox: require('@/assets/images/browsers/firefox.png'),
        Safari: require('@/assets/images/browsers/safari.png'),
        Edge: require('@/assets/images/browsers/edge.png'),
        Opera: require('@/assets/images/browsers/opera.png'),
        Postman: require('@/assets/images/browsers/postman.png'),
        Unknown: require('@/assets/images/browsers/unknown.png'),
      },
      aiStats: {
        totalRequests: 0,
        avgProcessingTime: 0,
        successRate: 0,
        topUsers: [],
      },
      aiChartOptions: {
        chart: {
          type: 'donut',
        },
        labels: ['Muvaffaqiyatli', 'Muvaffaqiyatsiz'],
        colors: ['#28c76f', '#ea5455'],
        legend: {
          show: true,
          position: 'bottom',
        },
      },
      aiChartSeries: [0, 0],
      aiTopUsersFields: [
        { key: 'ipAddress', label: 'IP Manzil' },
        { key: 'requestCount', label: 'So\'rovlar soni' },
        { key: 'avgProcessingTime', label: 'O\'rtacha vaqt (ms)' },
        { key: 'successRate', label: 'Muvaffaqiyat darajasi' },
      ],
      perPage: 10,
      currentPage: 1,
      methodChartOptions: {
        chart: { type: 'pie' },
        labels: ['GET', 'POST', 'PUT', 'DELETE', 'OTHER'],
        colors: ['#7367f0', '#28c76f', '#ff9f43', '#ea5455', '#82868b'],
        legend: { position: 'bottom' }
      },
      methodChartSeries: [0, 0, 0, 0, 0],

      statusChartOptions: {
        chart: { type: 'pie' },
        labels: ['Success (2xx)', 'Redirect (3xx)', 'Client Error (4xx)', 'Server Error (5xx)'],
        colors: ['#28c76f', '#ff9f43', '#ea5455', '#82868b'],
        legend: { position: 'bottom' }
      },
      statusChartSeries: [0, 0, 0, 0],
      browserChartOptions: {
        chart: { type: 'pie' },
        labels: ['Chrome', 'Firefox', 'Safari', 'Edge', 'Other'],
        colors: ['#7367f0', '#28c76f', '#ff9f43', '#ea5455', '#82868b'],
        legend: { position: 'bottom' },
        tooltip: {
          y: {
            formatter(val) {
              return `${val} foydalanuvchi`
            },
          },
        },
      },
      browserChartSeries: [0, 0, 0, 0, 0],
      suspiciousIPs: [],
      suspiciousIPFields: [
        { key: 'ipAddress', label: 'IP Manzil' },
        { key: 'requestCount', label: 'So\'rovlar soni' },
        { key: 'lastAccess', label: 'So\'nggi faollik' },
        { key: 'reason', label: 'Xavfli deb topilish sababi' },
        { key: 'actions', label: 'Amallar' }
      ],
    }
  },
  computed: {
    topIPsCount() {
      return this.statistics.topIPs.length
    },
    getModalTitle() {
      return this.selectedLog ? `So'rovlar tarixi - ${this.selectedLog.computerName}` : ''
    },
    totalPages() {
      return Math.ceil(this.totalRows / this.perPage)
    },
    totalRows() {
      return this.groupedLogs.length
    },
  },
  created() {
    this.fetchStatistics()
    this.fetchGroupedLogs()
    this.fetchAIStats()
    this.fetchSuspiciousIPs()
  },
  methods: {
    async fetchStatistics() {
      try {
        const response = await axios('/logs/statistics')
        response.data.topIPs = response.data.topIPs.slice(0, 7)

        // Xavfli IP manzillarni aniqlash
        await this.fetchSuspiciousIPs() // Avval xavfli IP larni aniqlaymiz

        // Xavfli IP lar sonini suspiciousIPs arrayidan olamiz
        response.data.suspiciousIPs = this.suspiciousIPs.length

        this.statistics = response.data
        this.updateChartData()
      } catch (error) {
        console.error('Statistikani yuklashda xatolik:', error)
      }
    },
    async fetchGroupedLogs() {
      try {
        const response = await axios.get('/logs/grouped')
        this.groupedLogs = response.data
        this.updateMethodChart()
        this.updateStatusChart()
        this.updateBrowserChart()
      } catch (error) {
        console.error('Guruhlangan loglarni yuklashda xatolik:', error)
      }
    },
    async fetchAIStats() {
      try {
        const response = await axios.get('/logs/grouped')
        const allLogs = response.data

        // AI so'rovlarini filtrlash
        const aiRequests = allLogs.reduce((acc, log) => {
          const aiCalls = log.requests.filter(req => req.uri.includes('/herbarium/send-for-ai'))
          if (aiCalls.length > 0) {
            acc.push({
              ipAddress: log.ipAddress,
              requestCount: aiCalls.length,
              avgProcessingTime: Math.round(aiCalls.reduce((sum, req) => sum + req.processingTime, 0) / aiCalls.length),
              successRate: Math.round((aiCalls.filter(req => req.status === 200).length / aiCalls.length) * 100),
              requests: aiCalls,
            })
          }
          return acc
        }, [])

        // AI statistikasini yangilash
        this.aiStats = {
          totalRequests: aiRequests.reduce((sum, user) => sum + user.requestCount, 0),
          avgProcessingTime: Math.round(aiRequests.reduce((sum, user) => sum + user.avgProcessingTime, 0) / (aiRequests.length || 1)),
          successRate: Math.round(aiRequests.reduce((sum, user) => sum + (user.successRate * user.requestCount), 0) /
            (aiRequests.reduce((sum, user) => sum + user.requestCount, 0) || 1)),
          topUsers: aiRequests.sort((a, b) => b.requestCount - a.requestCount),
        }

        this.updateAIChartData()
      } catch (error) {
        console.error('AI statistikasini yuklashda xatolik:', error)
      }
    },
    formatDateTime(dateArray) {
      if (!dateArray) return ''
      const date = new Date(
        dateArray[0],
        dateArray[1] - 1,
        dateArray[2],
        dateArray[3],
        dateArray[4],
        dateArray[5]
      )
      return date.toLocaleString('uz-UZ')
    },
    getStatusVariant(status) {
      if (status >= 200 && status < 300) return 'success'
      if (status >= 300 && status < 400) return 'warning'
      return 'danger'
    },
    updateChartData() {
      const categories = this.statistics.topIPs.map(ip => ip.ipAddress)
      const data = this.statistics.topIPs.map(ip => ip.requestCount)
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: { categories },
      }
      this.chartSeries = [{
        name: "So'rovlar",
        data,
      }]
    },
    showRequestDetails(log) {
      this.selectedLog = {
        ...log,
        requests: log.requests.map(req => ({
          ...req,
          browserInfo: this.getBrowserInfo(req.userAgent)
        }))
      }
      this.showModal = true
    },
    getMethodVariant(method) {
      switch (method) {
        case 'GET':
          return 'primary'
        case 'POST':
          return 'success'
        case 'PUT':
          return 'warning'
        case 'DELETE':
          return 'danger'
        default:
          return 'secondary'
      }
    },
    getBrowserImage(userAgent) {
      if (!userAgent) return this.browserImages.Unknown

      if (userAgent.includes('Chrome')) return this.browserImages.Chrome
      if (userAgent.includes('Firefox')) return this.browserImages.Firefox
      if (userAgent.includes('Safari')) return this.browserImages.Safari
      if (userAgent.includes('Edge')) return this.browserImages.Edge
      if (userAgent.includes('Opera') || userAgent.includes('OPR')) return this.browserImages.Opera
      if (userAgent.includes('Postman')) return this.browserImages.Postman

      return this.browserImages.Unknown
    },
    getBrowserName(userAgent) {
      if (!userAgent) return 'Unknown'

      const isMobile = /Mobile|Android|iPhone|iPad/i.test(userAgent)
      const mobileSuffix = isMobile ? ' (Mobile)' : ''

      if (userAgent.includes('Chrome')) return `Chrome${mobileSuffix}`
      if (userAgent.includes('Firefox')) return `Firefox${mobileSuffix}`
      if (userAgent.includes('Safari')) return `Safari${mobileSuffix}`
      if (userAgent.includes('Edge')) return `Edge${mobileSuffix}`
      if (userAgent.includes('Opera') || userAgent.includes('OPR')) return `Opera${mobileSuffix}`
      if (userAgent.includes('Postman')) return 'Postman'

      return 'Unknown'
    },
    getBrowserInfo(userAgent) {
      if (!userAgent) return { name: 'Unknown', icon: 'chrome' }

      const browsers = {
        'Chrome': {
          pattern: /Chrome/i,
          icon: 'chrome',
          mobile: /Mobile/i.test(userAgent) ? '(Mobile)' : ''
        },
        'Firefox': {
          pattern: /Firefox/i,
          icon: 'firefox',
          mobile: /Mobile|Android/i.test(userAgent) ? '(Mobile)' : ''
        },
        'Safari': {
          pattern: /Safari/i,
          icon: 'safari',
          mobile: /Mobile|iPhone|iPad/i.test(userAgent) ? '(Mobile)' : ''
        },
        'Edge': {
          pattern: /Edge/i,
          icon: 'edge',
          mobile: /Mobile/i.test(userAgent) ? '(Mobile)' : ''
        },
        'Opera': {
          pattern: /Opera|OPR/i,
          icon: 'opera',
          mobile: /Mobile/i.test(userAgent) ? '(Mobile)' : ''
        },
        'Postman': {
          pattern: /Postman/i,
          icon: 'tool',
          mobile: ''
        }
      }

      for (const [name, info] of Object.entries(browsers)) {
        if (info.pattern.test(userAgent)) {
          return {
            name: `${name} ${info.mobile}`,
            icon: info.icon
          }
        }
      }

      return { name: 'Unknown', icon: 'chrome' }
    },
    getSuccessRateVariant(rate) {
      if (rate >= 80) return 'success'
      if (rate >= 60) return 'warning'
      return 'danger'
    },
    updateAIChartData() {
      const successRate = this.aiStats.successRate
      this.aiChartSeries = [successRate, 100 - successRate]
    },
    updateMethodChart() {
      const methodCounts = this.groupedLogs.reduce((acc, log) => {
        log.requests.forEach(req => {
          acc[req.method] = (acc[req.method] || 0) + 1
        })
        return acc
      }, {})

      this.methodChartSeries = [
        methodCounts.GET || 0,
        methodCounts.POST || 0,
        methodCounts.PUT || 0,
        methodCounts.DELETE || 0,
        Object.values(methodCounts).reduce((a, b) => a + b, 0) -
          (methodCounts.GET + methodCounts.POST + methodCounts.PUT + methodCounts.DELETE)
      ]
    },

    updateStatusChart() {
      const statusCounts = this.groupedLogs.reduce((acc, log) => {
        log.requests.forEach(req => {
          const status = Math.floor(req.status / 100)
          acc[status] = (acc[status] || 0) + 1
        })
        return acc
      }, {})

      this.statusChartSeries = [
        statusCounts[2] || 0,
        statusCounts[3] || 0,
        statusCounts[4] || 0,
        statusCounts[5] || 0
      ]
    },
    updateBrowserChart() {
      const browserCounts = this.groupedLogs.reduce((acc, log) => {
        const browser = this.getBrowserName(log.userAgent).split(' ')[0]
        acc[browser] = (acc[browser] || 0) + 1
        return acc
      }, {})

      this.browserChartSeries = [
        browserCounts.Chrome || 0,
        browserCounts.Firefox || 0,
        browserCounts.Safari || 0,
        browserCounts.Edge || 0,
        Object.values(browserCounts).reduce((a, b) => a + b, 0) -
          (browserCounts.Chrome + browserCounts.Firefox + browserCounts.Safari + browserCounts.Edge || 0),
      ]
    },
    // Xavfli IP manzillarni aniqlash uchun metodlar
    hasFailedAttempts(ipAddress) {
      const requests = this.groupedLogs.find(log => log.ipAddress === ipAddress)?.requests || []

      // Agar so'rovlar bo'sh bo'lsa
      if (requests.length === 0) return false

      // 1. So'rovlar soni tekshiruvi
      const requestsPerHour = this.getRequestsPerHour(requests)
      if (requestsPerHour > 50) return true // Soatiga 50 dan ortiq so'rov

      // 2. Xatoliklar soni tekshiruvi
      const errorRequests = requests.filter(req => req.status >= 400)
      const errorRate = errorRequests.length / requests.length
      if (errorRate > 0.3) return true // 30% dan ortiq xatolik

      // 3. Shubhali so'rovlar tekshiruvi
      const suspiciousPatterns = [
        /admin/i,          // Admin panelga urinishlar
        /\.php$/i,         // PHP fayllarni qidirish
        /\.(git|env)/i,    // Maxfiy fayllarni qidirish
        /'|--|;/i,         // SQL injection
        /\.\.|\/\//i       // Path traversal
      ]

      const hasSuspiciousRequests = requests.some(req =>
        suspiciousPatterns.some(pattern => pattern.test(req.uri))
      )

      return hasSuspiciousRequests
    },

    getRequestsPerHour(requests) {
      if (requests.length < 2) return 0

      // So'rovlarni vaqt bo'yicha saralash
      const sortedRequests = [...requests].sort((a, b) => {
        const timeA = new Date(...a.time)
        const timeB = new Date(...b.time)
        return timeA - timeB
      })

      // Birinchi va oxirgi so'rov orasidagi vaqt (soatda)
      const firstTime = new Date(...sortedRequests[0].time)
      const lastTime = new Date(...sortedRequests[sortedRequests.length - 1].time)
      const hoursDiff = (lastTime - firstTime) / (1000 * 60 * 60)

      // Soatiga o'rtacha so'rovlar soni
      return requests.length / (hoursDiff || 1)
    },
    // Xavfli IP larni aniqlash va ma'lumotlarini to'plash
    async fetchSuspiciousIPs() {
      try {
        const suspiciousIPs = []

        // Avval barcha loglarni olish
        const logsResponse = await axios.get('/logs/grouped')
        this.groupedLogs = logsResponse.data

        for (const ip of this.statistics.topIPs || []) {
          const requests = this.groupedLogs.find(log => log.ipAddress === ip.ipAddress)?.requests || []
          const reasons = []

          // Tekshiruvlar...
          if (ip.requestCount > 100) {
            reasons.push('Ko\'p so\'rovlar')
          }

          const requestsPerHour = this.getRequestsPerHour(requests)
          if (requestsPerHour > 50) {
            reasons.push('Tez-tez so\'rovlar')
          }

          const errorRequests = requests.filter(req => req.status >= 400)
          const errorRate = errorRequests.length / requests.length
          if (errorRate > 0.3) {
            reasons.push('Ko\'p xatoliklar')
          }

          const suspiciousPatterns = {
            'Admin panel': /admin/i,
            'PHP fayl': /\.php$/i,
            'Maxfiy fayl': /\.(git|env)/i,
            'SQL injection': /'|--|;/i,
            'Path traversal': /\.\.|\/\//i
          }

          for (const [name, pattern] of Object.entries(suspiciousPatterns)) {
            if (requests.some(req => pattern.test(req.uri))) {
              reasons.push(name)
            }
          }

          // Agar birorta sabab topilgan bo'lsa
          if (reasons.length > 0) {
            suspiciousIPs.push({
              ipAddress: ip.ipAddress,
              requestCount: ip.requestCount,
              lastAccess: requests[requests.length - 1]?.time,
              reasons,
              requests: requests.filter(req =>
                req.status >= 400 ||
                Object.values(suspiciousPatterns).some(pattern => pattern.test(req.uri))
              )
            })
          }
        }

        this.suspiciousIPs = suspiciousIPs
        return suspiciousIPs
      } catch (error) {
        console.error('Xavfli IP larni aniqlashda xatolik:', error)
        return []
      }
    },

    // Xavfli IP ning batafsil ma'lumotlarini ko'rsatish
    showSuspiciousDetails(ip) {
      this.selectedLog = {
        computerName: `Xavfli IP - ${ip.ipAddress}`,
        requests: ip.requests
      }
      this.showModal = true
    }
  },
}
</script>

<style lang="scss" scoped>
.avatar {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-light-primary {
  background-color: rgba(115, 103, 240, 0.12);
}

.bg-light-success {
  background-color: rgba(40, 199, 111, 0.12);
}

.bg-light-warning {
  background-color: rgba(255, 159, 67, 0.12);
}

.bg-light-danger {
  background-color: rgba(234, 84, 85, 0.12);
}

.text-primary {
  color: #7367f0;
}

.text-success {
  color: #28c76f;
}

.text-warning {
  color: #ff9f43;
}

.text-danger {
  color: #ea5455;
}

.modal-body {
  max-height: 70vh;
  overflow-y: auto;
}

.badge {
  font-size: 0.85rem;
  padding: 0.35em 0.65em;
}

table {
  th, td {
    vertical-align: middle;
  }
}

.browser-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  border-radius: 4px;
}

.browser-info {
  display: flex;
  align-items: center;

  span {
    font-size: 0.9rem;
  }
}

.bg-light-info {
  background-color: rgba(0, 207, 232, 0.12);
}

.text-info {
  color: #00cfe8;
}

.progress-sm {
  height: 6px;
}

.p-50 {
  padding: 1.25rem !important;
}
</style>
