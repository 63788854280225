<template>
  <div class="development-container">
    <!-- Header section -->
    <b-card class="header-card mb-2">
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="mb-0">Rivojlanish bo'limi</h2>
        <b-button
          to="/development"
          variant="primary"
          class="d-flex align-items-center"
        >
          <feather-icon icon="PlusCircleIcon" class="mr-1" size="16"/>
          Yangi qo'shish
        </b-button>
      </div>
    </b-card>

    <!-- Main content -->
    <b-card class="main-content-card">
      <div class="d-flex justify-content-left align-items-center mb-2">
        <h3 class="mb-0">Rivojlanish ro'yxati</h3>
        <b-form-input 
          v-model="searchQuery"
          placeholder="Qidirish..."
          class="search-input"
          style="max-width: 500px; margin-left: 10px;"
        />
      </div>

      <!-- Table section -->
      <div class="position-relative">
        <cyber-loading v-if="loading"/>
        <b-table
          hover
          responsive
          :items="filteredItems"
          :fields="fields"
          :current-page="currentPage"
          class="custom-table"
          :busy="loading"
        >
          <!-- Title column -->
          <template #cell(titleUz)="data">
            <div class="d-flex align-items-center">
              <div class="font-weight-bold">{{ data.value }}</div>
            </div>
          </template>

          <!-- Content column -->
          <template #cell(contentUz)="data">
            <div v-html="truncateContent(data.value)" class="content-cell"/>
          </template>
          
          <!-- URL column -->
          <template #cell(url)="data">
            <div v-if="data.value" class="video-link">
              <b-button
                variant="outline-secondary"
                size="sm"
                v-b-tooltip.hover
                :title="data.value"
                @click="openVideo(data.value)"
              >
                <feather-icon icon="PlayCircleIcon" size="16"/>
                Video
              </b-button>
            </div>
            <span v-else>-</span>
          </template>

          <!-- Date column -->
          <template #cell(createdAt)="data">
            {{ formatDate(data.value) }}
          </template>

          <!-- Actions column -->
          <template #cell(actions)="data">
            <div class="d-flex justify-content-end align-items-center">
              <b-button
                variant="outline-primary"
                size="sm"
                class="mr-1"
                @click="editeDevelopmentList(data.item.id)"
              >
                <feather-icon icon="EditIcon" size="16"/>
              </b-button>
              <b-button
                variant="outline-info"
                size="sm"
                @click="infoDevelopmentList(data.item.id)"
              >
                <feather-icon icon="EyeIcon" size="16"/>
              </b-button>
            </div>
          </template>
        </b-table>
      </div>

      <!-- Pagination -->
      <div class="d-flex justify-content-between align-items-center mt-2">
        <div>
          <b-form-select
            v-model="perPage"
            :options="[5, 10, 25, 50]"
            class="per-page-select"
          />
          <span class="ml-2">ta yozuv sahifada</span>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="mb-0"
        />
      </div>
    </b-card>

    <!-- Video Modal -->
    <b-modal
      v-model="showVideoModal"
      size="lg"
      centered
      hide-footer
      hide-header
      body-class="p-0"
      dialog-class="video-dialog"
    >
      <div class="video-player-container">
        <div id="player" data-plyr-provider="youtube" :data-plyr-embed-id="videoId"></div>
        <b-button
          class="close-btn"
          variant="light"
          @click="closeVideo"
        >
          <feather-icon icon="XIcon" size="24"/>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BTable, BPagination, 
  BFormInput, BFormSelect, BOverlay,
} from 'bootstrap-vue'
import CyberLoading from '@/views/extensions/components/loading/cyberLoading.vue'

export default {
  components: {
    BCard,
    BButton, 
    BTable,
    BPagination,
    BFormInput,
    BFormSelect,
    BOverlay,
    CyberLoading,
  },
  
  data() {
    return {
      loading: false,
      items: [],
      searchQuery: '',
      fields: [
        { key: 'id', label: 'ID', sortable: true, class: 'text-center' },
        { key: 'titleUz', label: 'Sarlavha', sortable: true },
        { key: 'contentUz', label: 'Mazmuni', sortable: true },
        { key: 'url', label: 'Video', sortable: true },
        { key: 'createdAt', label: 'Sana', sortable: true },
        { key: 'actions', label: 'Amallar', class: 'text-right' },
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      showVideoModal: false,
      videoUrl: null,
      player: null,
      videoId: null,
    }
  },

  computed: {
    filteredItems() {
      if (!this.searchQuery) return this.items
      
      const query = this.searchQuery.toLowerCase()
      return this.items.filter(item => 
        item.titleUz?.toLowerCase().includes(query) ||
        item.contentUz?.toLowerCase().includes(query)
      )
    }
  },

  watch: {
    currentPage() {
      this.getDevelopment()
    },
    perPage() {
      this.currentPage = 1
      this.getDevelopment()
    }
  },

  created() {
    this.getDevelopment()
  },

  mounted() {
    // Plyr CDN skriptini yuklash
    const script = document.createElement('script')
    script.src = 'https://cdn.plyr.io/3.7.8/plyr.js'
    script.async = true
    document.head.appendChild(script)

    // Plyr CSS ni yuklash
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'https://cdn.plyr.io/3.7.8/plyr.css'
    document.head.appendChild(link)

    script.onload = () => {
      this.initPlayer()
    }
  },

  methods: {
    async getDevelopment() {
      try {
        this.loading = true
        const currentPage = this.currentPage - 1
        
        const requestBody = {
          ageCategoryId: null,
        }

        const response = await this.$http.post(
          `/rivojlanish/find-all?page=${currentPage}&size=${this.perPage}`,
          requestBody,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )

        if (response.data) {
          this.totalRows = response.data.totalElements
          this.items = response.data.content
        }

      } catch (error) {
        console.error('API xatolik:', error)
        this.$bvToast.toast(`Xatolik: ${error.response?.data?.message || 'Serverga ulanishda xatolik'}`, {
          title: 'Xatolik',
          variant: 'danger',
          solid: true,
        })
      } finally {
        this.loading = false
      }
    },

    formatDate(timestamp) {
      if (!timestamp) return ''
      const date = new Date(timestamp)
      return new Intl.DateTimeFormat('uz-UZ', {
        year: 'numeric',
        month: '2-digit', 
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date)
    },

    truncateContent(content) {
      if (!content) return ''
      // HTML teglarni olib tashlash
      const plainText = content.replace(/<[^>]*>/g, '')
      return plainText.length > 100 
        ? plainText.substr(0, 100) + '...' 
        : plainText
    },

    editeDevelopmentList(id) {
      this.$router.push({
        name: 'development',
        params: { id }
      })
    },

    infoDevelopmentList(id) {
      this.$router.push({
        name: 'development-blog-detail',
        params: { id }
      })
    },

    openVideo(url) {
      if (!url) return
      // YouTube video ID ni olish
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = url.match(regExp)
      this.videoId = match && match[2].length === 11 ? match[2] : null
      
      if (this.videoId) {
        this.showVideoModal = true
        // Player qayta ishga tushirish
        this.$nextTick(() => {
          this.initPlayer()
        })
      }
    },

    closeVideo() {
      this.showVideoModal = false
      this.videoId = null
    },

    initPlayer() {
      if (window.Plyr) {
        const player = new window.Plyr('#player', {
          controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.development-container {
  .header-card {
    background: linear-gradient(to right, #7367f0, #9e95f5);
    h2 {
      color: white;
    }
  }

  .main-content-card {
    border-radius: 0.5rem;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  }

  .custom-table {
    :deep(th) {
      background-color: #f8f8f8;
      border-top: 2px solid #7367f0;
      
      &.text-right {
        text-align: right;
      }
    }

    :deep(td) {
      vertical-align: middle;
      
      &.text-right {
        text-align: right;
      }
    }
  }

  .content-cell {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .per-page-select {
    width: 80px;
    display: inline-block;
  }

  .position-relative {
    position: relative;
    min-height: 200px;
  }

  .video-link {
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .video-player-container {
    position: relative;
    background: #000;
    border-radius: 8px;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 nisbat */
  }

  #player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  :deep(.video-dialog) {
    max-width: 900px;
    margin: 1.75rem auto;
  }

  :deep(.plyr--video) {
    min-height: 500px;
  }

  :deep(.plyr--full-ui input[type=range]) {
    color: #7367f0;
  }

  :deep(.plyr__control--overlaid) {
    background: rgba(115, 103, 240, 0.8);
  }

  :deep(.plyr--video .plyr__control:hover) {
    background: #7367f0;
  }
}
</style>
