<template>
  <div class="emlash-container">
    <b-card>
      <div class="modern-header">
        <div class="d-flex justify-content-between align-items-center">
          <div class="header-title">
            <h3 class="mb-0">Foydalanuvchilar va ularning bolalari</h3>
          </div>
        </div>
      </div>

      <!-- Filter paneli -->
      <div class="filter-panel p-2">
        <b-row>
          <b-col md="4" class="mb-2">
            <b-form-group label="Qidirish">
              <b-input-group>
                <b-form-input
                  v-model="search"
                  placeholder="Foydalanuvchi nomi bo'yicha qidirish..."
                  @keyup.enter="handleSearch"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    @click="handleSearch"
                  >
                    <feather-icon icon="SearchIcon" size="16"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="3" class="mb-2">
            <b-form-group label="Jinsi bo'yicha">
              <b-form-select
                v-model="genderFilter"
                :options="genderOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="3" class="mb-2">
            <b-form-group label="Muddati bo'yicha">
              <b-form-select
                v-model="earlyBornFilter"
                :options="earlyBornOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="2" class="mb-2">
            <b-form-group label="Bolalar soni">
              <b-form-select
                v-model="childrenCountFilter"
                :options="childrenCountOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <b-card-body>
        <!-- Jadval -->
        <div class="table-responsive">
          <b-table
            :items="filteredUserChildrenStats"
            :fields="fields"
            striped
            hover
            :per-page="perPage"
            :current-page="currentPage"
            :busy="isLoading"
            show-empty
          >
            <template #cell(actions)="row">
              <b-button
                variant="primary"
                size="sm"
                @click="showChildrenDetails(row.item)"
              >
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="mr-1"
                />
                Ko'rish
              </b-button>
            </template>
            <template #table-busy>
              <div class="modern-loading-overlay">
                <div class="modern-loading-content">
                  <div class="cyber-loader">
                    <div class="cyber-circle"></div>
                    <div class="cyber-circle"></div>
                    <div class="cyber-circle"></div>
                    <div class="cyber-circle"></div>
                    <div class="cyber-core"></div>
                    <div class="cyber-line line-1"></div>
                    <div class="cyber-line line-2"></div>
                    <div class="cyber-line line-3"></div>
                    <div class="cyber-line line-4"></div>
                  </div>
                  <div class="neon-text">
                    <span data-text="M">M</span>
                    <span data-text="A">A</span>
                    <span data-text="'">&#39;</span>
                    <span data-text="L">L</span>
                    <span data-text="U">U</span>
                    <span data-text="M">M</span>
                    <span data-text="O">O</span>
                    <span data-text="T">T</span>
                    <span data-text="L">L</span>
                    <span data-text="A">A</span>
                    <span data-text="R">R</span>
                  </div>
                </div>
              </div>
            </template>
            <template #empty>
              <div class="text-center text-muted my-2">
                Ma'lumotlar topilmadi
              </div>
            </template>
          </b-table>
        </div>

        <!-- Pagination -->
        <div class="mt-3">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <label class="mr-2 mb-0">Sahifada:</label>
              <b-form-select
                v-model="perPage"
                :options="pageOptions"
                size="sm"
                class="w-auto"
              />
            </div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="my-0"
            />
          </div>
        </div>

        <!-- Modal -->
        <b-modal
          v-model="isModalVisible"
          :title="modalTitle"
          size="lg"
          scrollable
        >
          <div class="children-list">
            <b-card
              v-for="child in childrenList"
              :key="child.id"
              class="mb-2"
              no-body
            >
              <b-card-body class="p-2">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h5 class="mb-1">{{ child.name || 'Nomsiz' }}</h5>
                    <div class="text-muted small">
                      Tug'ilgan sana: {{ formatBirthDate(child.birthDate) }}
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <b-badge
                      :variant="child.type === 'MALE' ? 'info' : 'pink'"
                      class="mr-2"
                    >
                      {{ child.type === 'MALE' ? 'Oʻgʻil' : 'Qiz' }}
                    </b-badge>
                    <b-badge
                      v-if="child.isBornEarly"
                      variant="warning"
                    >
                      Muddatidan oldin
                    </b-badge>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </div>
          <template #modal-footer>
            <div class="w-100">
              <div class="text-muted small float-left">
                Jami: {{ childrenCount }} ta bola
              </div>
              <b-button
                variant="secondary"
                @click="isModalVisible = false"
              >
                Yopish
              </b-button>
            </div>
          </template>
        </b-modal>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BTable,
  BBadge,
  BButton,
  BModal,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BFormSelect,
  BRow,
  BCol,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  name: 'ChildList',
  components: {
    BCard,
    BCardBody,
    BTable,
    BBadge,
    BButton,
    BModal,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    BPagination,
    BSpinner,
  },
  data() {
    return {
      children: [],
      isModalVisible: false,
      selectedUser: null,
      fields: [
        { key: 'username', label: 'Foydalanuvchi' },
        { key: 'childrenCount', label: 'Bolalar soni' },
        {
          key: 'maleCount',
          label: 'O\'g\'il bolalar',
          formatter: value => `${value} ta`,
        },
        {
          key: 'femaleCount',
          label: 'Qiz bolalar',
          formatter: value => `${value} ta`,
        },
        {
          key: 'earlyBornCount',
          label: 'Muddatidan oldin',
          formatter: value => (value ? `${value} ta` : '-'),
        },
        { key: 'actions', label: 'Amallar' },
      ],
      search: '',
      genderFilter: null,
      earlyBornFilter: null,
      childrenCountFilter: null,
      searchQuery: '',
      perPage: 9,
      currentPage: 1,
      pageOptions: [9, 20, 50, 100],
      isLoading: true,
    }
  },
  computed: {
    userChildrenStats() {
      const userGroups = {}
      this.children.forEach(child => {
        if (!userGroups[child.username]) {
          userGroups[child.username] = {
            username: child.username,
            children: [],
            childrenCount: 0,
            maleCount: 0,
            femaleCount: 0,
            earlyBornCount: 0,
          }
        }
        const group = userGroups[child.username]
        group.children.push(child)
        group.childrenCount += 1
        if (child.type === 'MALE') {
          group.maleCount += 1
        } else {
          group.femaleCount += 1
        }
        if (child.isBornEarly) {
          group.earlyBornCount += 1
        }
      })
      return Object.values(userGroups).sort((a, b) => b.childrenCount - a.childrenCount)
    },
    modalTitle() {
      return this.selectedUser ? `${this.selectedUser.username} foydalanuvchining bolalari` : ''
    },
    childrenList() {
      return this.selectedUser ? this.selectedUser.children : []
    },
    childrenCount() {
      return this.selectedUser ? this.selectedUser.children.length : 0
    },
    totalChildren() {
      return this.children.length
    },
    totalMale() {
      return this.children.filter(child => child.type === 'MALE').length
    },
    totalFemale() {
      return this.children.filter(child => child.type === 'FEMALE').length
    },
    totalEarlyBorn() {
      return this.children.filter(child => child.isBornEarly).length
    },
    genderOptions() {
      return [
        { value: null, text: 'Barchasi' },
        { value: 'MALE', text: 'O\'g\'il bolalar' },
        { value: 'FEMALE', text: 'Qiz bolalar' },
      ]
    },
    earlyBornOptions() {
      return [
        { value: null, text: 'Barchasi' },
        { value: true, text: 'Muddatidan oldin' },
        { value: false, text: 'O\'z muddatida' },
      ]
    },
    childrenCountOptions() {
      return [
        { value: null, text: 'Barchasi' },
        { value: 1, text: '1 ta bola' },
        { value: 2, text: '2 ta bola' },
        { value: 3, text: '3 ta bola' },
        { value: 4, text: '4 va undan ko\'p' },
      ]
    },
    filteredUserChildrenStats() {
      let result = this.userChildrenStats

      if (this.searchQuery) {
        const searchLower = this.searchQuery.toLowerCase()
        result = result.filter(user =>
          user.username.toLowerCase().includes(searchLower) ||
          user.children.some(child => child.name && child.name.toLowerCase().includes(searchLower))
        )
      }

      if (this.genderFilter) {
        result = result.filter(user => {
          if (this.genderFilter === 'MALE') {
            return user.maleCount > 0
          }
          return user.femaleCount > 0
        })
      }

      if (this.earlyBornFilter !== null) {
        result = result.filter(user => {
          const hasEarlyBorn = user.earlyBornCount > 0
          return this.earlyBornFilter ? hasEarlyBorn : !hasEarlyBorn
        })
      }

      if (this.childrenCountFilter) {
        result = result.filter(user => {
          if (this.childrenCountFilter === 4) {
            return user.childrenCount >= 4
          }
          return user.childrenCount === this.childrenCountFilter
        })
      }

      return result
    },
    totalRows() {
      return this.filteredUserChildrenStats.length
    },
  },
  async created() {
    await this.fetchChildren()
  },
  methods: {
    async fetchChildren() {
      this.isLoading = true
      try {
        const response = await axios.get('https://bolajon.mtsh.uz/api/child/get-all-children', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        this.children = response.data
      } catch (error) {
        console.error('Bolalar maʼlumotlarini yuklashda xatolik:', error)
      } finally {
        this.isLoading = false
      }
    },
    formatBirthDate(timestamp) {
      const date = new Date(timestamp * 1000)
      return date.toLocaleDateString('uz-UZ')
    },
    showChildrenDetails(user) {
      this.selectedUser = user
      this.isModalVisible = true
    },
    handleSearch() {
      this.searchQuery = this.search.trim()
    },
  },
}
</script>

<style lang="scss" scoped>
.emlash-container {
  background: #f8fafc;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.modern-header {
  background: linear-gradient(to right, #7367f0, #9e95f5);
  padding: 1.5rem;
  border-radius: 0.5rem 0.5rem 0 0;

  .header-title h3 {
    color: white;
    margin: 0;
  }
}

.table-responsive {
  max-height: 600px;
  overflow-y: auto;
}

.pagination {
  margin-bottom: 1rem;
}

.per-page-select {
  width: 80px;
}

.filter-panel {
  background: #f8f9fa;
  border-radius: 0.5rem;
  margin: 1rem;
  padding: 1rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

// Responsive stillar
@media (max-width: 768px) {
  .per-page-select {
    width: 70px;
  }
}

.modern-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 80vh;
  background: rgba(14, 14, 22, 0.97);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modern-loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.cyber-loader {
  position: relative;
  width: 150px;
  height: 150px;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.cyber-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-radius: 50%;
  animation: cyberRotate 3s linear infinite;

  &:nth-child(1) {
    border-top-color: #7367f0;
    animation-delay: 0s;
  }
  &:nth-child(2) {
    border-right-color: #28c76f;
    animation-delay: 0.5s;
  }
  &:nth-child(3) {
    border-bottom-color: #ea5455;
    animation-delay: 1s;
  }
  &:nth-child(4) {
    border-left-color: #ff9f43;
    animation-delay: 1.5s;
  }
}

.cyber-core {
  position: absolute;
  width: 30%;
  height: 30%;
  background: radial-gradient(circle, #7367f0, #4839e5);
  border-radius: 50%;
  left: 35%;
  top: 35%;
  animation: pulseCore 2s ease-in-out infinite;
  box-shadow: 0 0 20px #7367f0;
}

.cyber-line {
  position: absolute;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #7367f0, transparent);
  animation: cyberScan 2s linear infinite;

  &.line-1 { top: 25%; transform: rotate(0deg); animation-delay: 0s; }
  &.line-2 { top: 75%; transform: rotate(0deg); animation-delay: 0.5s; }
  &.line-3 { left: 25%; width: 2px; height: 100%; animation-delay: 1s; }
  &.line-4 { left: 75%; width: 2px; height: 100%; animation-delay: 1.5s; }
}

.neon-text {
  display: flex;
  gap: 8px;

  span {
    position: relative;
    display: inline-block;
    font-size: 2rem;
    font-weight: bold;
    color: #7367f0;
    text-shadow: 0 0 10px #7367f0,
                 0 0 20px #7367f0,
                 0 0 40px #7367f0;
    animation: neonGlow 2s ease-in-out infinite;

    &::before {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      filter: blur(15px);
      opacity: 0.5;
      color: #7367f0;
    }

    @for $i from 1 through 11 {
      &:nth-child(#{$i}) {
        animation-delay: #{$i * 0.1}s;
      }
    }
  }
}

@keyframes cyberRotate {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
}

@keyframes cyberScan {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes pulseCore {
  0%, 100% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
}

@keyframes neonGlow {
  0%, 100% {
    filter: brightness(1);
    transform: scale(1) translateY(0);
  }
  50% {
    filter: brightness(1.5);
    transform: scale(1.1) translateY(-5px);
  }
}

// Responsive stillar
@media (max-width: 768px) {
  .cyber-loader {
    width: 120px;
    height: 120px;
  }

  .neon-text span {
    font-size: 1.5rem;
  }
}

// Hover effektlar
.cyber-loader:hover {
  .cyber-core {
    animation: pulseCore 1s ease-in-out infinite;
  }

  .cyber-circle {
    animation-duration: 2s;
  }
}

// Glass effect
.modern-loading-overlay {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg,
      rgba(115, 103, 240, 0.1),
      rgba(40, 199, 111, 0.1),
      rgba(234, 84, 85, 0.1),
      rgba(255, 159, 67, 0.1)
    );
    filter: blur(20px);
    z-index: -1;
  }
}
</style>
