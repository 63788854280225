<template>
  <b-row class="justify-content-center">
    <b-col cols="12">
      <!-- Animatsiyali ogohlantirish -->
      <div class="notification-banner mb-4">
        <div class="notification-glow"></div>
        <div class="notification-content">
          <div class="notification-icon pulse">
            <feather-icon icon="ShieldIcon" size="20" class="text-warning" />
          </div>
          <div class="notification-text">
            <strong class="glow-text">Muhim eslatma!</strong>
            <span class="message-text">Maxfiylik siyosati foydalanuvchilar uchun muhim hujjat hisoblanadi. Bir vaqtning o'zida faqat bitta maxfiylik siyosati faol holatda bo'lishi kerak.</span>
          </div>
        </div>
        <div class="notification-wave"></div>
      </div>

      <b-card>
        <!-- Yuqori qism - Statistika -->
        <b-row class="mb-2">
          <b-col md="4" sm="12">
            <b-card
              bg-variant="light"
              class="status-card cursor-pointer"
              :class="{ active: filterStatus === 'active' }"
              @click="filterStatus = 'active'"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="mb-1">Faol</h6>
                  <h3 class="mb-0 text-success">{{ activeCount }}</h3>
                </div>
                <div class="status-icon bg-success-light">
                  <feather-icon icon="CheckCircleIcon" class="text-success"/>
                </div>
              </div>
            </b-card>
          </b-col>

          <b-col md="4" sm="12">
            <b-card
              bg-variant="light"
              class="status-card cursor-pointer"
              :class="{ active: filterStatus === 'deleted' }"
              @click="filterStatus = 'deleted'"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="mb-1">O'chirilgan</h6>
                  <h3 class="mb-0 text-danger">{{ deletedCount }}</h3>
                </div>
                <div class="status-icon bg-danger-light">
                  <feather-icon icon="ArchiveIcon" class="text-danger"/>
                </div>
              </div>
            </b-card>
          </b-col>

          <b-col md="4" sm="12">
            <b-card
              bg-variant="light"
              class="status-card cursor-pointer"
              :class="{ active: filterStatus === 'all' }"
              @click="filterStatus = 'all'"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="mb-1">Jami</h6>
                  <h3 class="mb-0 text-primary">{{ totalRows }}</h3>
                </div>
                <div class="status-icon bg-primary-light">
                  <feather-icon icon="LayersIcon" class="text-primary"/>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <!-- Qo'shish tugmasi -->
        <div class="d-flex justify-content-end mb-2">
          <b-button
            variant="primary"
            @click="showAddModal"
          >
            <feather-icon icon="PlusIcon" class="mr-1"/> Yangi qo'shish
          </b-button>
        </div>

        <!-- Ma'lumotlar jadvali -->
        <b-table
          :items="filteredItems"
          :fields="fields"
          responsive="sm"
          hover
          striped
          show-empty
        >
          <template #cell(status)="data">
            <b-badge :variant="data.item.isDeleted ? 'danger' : 'success'">
              {{ data.item.isDeleted ? 'O\'chirilgan' : 'Faol' }}
            </b-badge>
          </template>

          <template #cell(createdDate)="data">
            {{ formatDate(data.item.createdDate) }}
          </template>

          <template #cell(actions)="row">
            <b-button-group size="sm">
              <b-button
                v-if="!row.item.isDeleted"
                variant="outline-primary"
                @click="editItem(row.item)"
              >
                <feather-icon icon="EditIcon" size="16"/>
              </b-button>
              <b-button
                v-if="!row.item.isDeleted"
                variant="outline-danger"
                @click="confirmDelete(row.item)"
              >
                <feather-icon icon="TrashIcon" size="16"/>
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          class="my-0"
        />
      </b-card>
    </b-col>

    <!-- Qo'shish/Tahrirlash modali -->
    <b-modal
      v-model="isModalVisible"
      :title="modalTitle"
      size="lg"
      @hidden="resetForm"
      @ok="handleSubmit"
    >
      <b-tabs content-class="mt-3">
        <!-- O'zbekcha tab -->
        <b-tab title="O'zbekcha" active>
          <b-form-group label="Maxfiylik siyosati (UZ)">
            <b-form-textarea
              v-model="form.contentUz"
              rows="6"
              placeholder="O'zbekcha matnni kiriting"
            />
          </b-form-group>
        </b-tab>

        <!-- Ruscha tab -->
        <b-tab title="Русский">
          <b-form-group label="Политика конфиденциальности (RU)">
            <b-form-textarea
              v-model="form.contentRu"
              rows="6"
              placeholder="Введите текст на русском"
            />
          </b-form-group>
        </b-tab>

        <!-- Inglizcha tab -->
        <b-tab title="English">
          <b-form-group label="Privacy Policy (ENG)">
            <b-form-textarea
              v-model="form.contentEng"
              rows="6"
              placeholder="Enter text in English"
            />
          </b-form-group>
        </b-tab>
      </b-tabs>
    </b-modal>

    <!-- O'chirish modali -->
    <b-modal
      v-model="isDeleteModalVisible"
      title="O'chirishni tasdiqlash"
      @ok="deleteItem"
    >
      <p>Haqiqatan ham bu maxfiylik siyosatini o'chirmoqchimisiz?</p>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BButton,
  BButtonGroup, BModal, BTabs, BTab, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  name: 'PrivacyPolicy',
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BButton,
    BButtonGroup,
    BModal,
    BTabs,
    BTab,
    BFormGroup,
    BFormTextarea,
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'contentUz', label: 'O\'zbekcha' },
        { key: 'contentRu', label: 'Русский' },
        { key: 'contentEng', label: 'English' },
        { key: 'createdDate', label: 'Yaratilgan sana' },
        { key: 'actions', label: 'Amallar' },
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      isModalVisible: false,
      isDeleteModalVisible: false,
      selectedItem: null,
      form: {
        contentUz: '',
        contentRu: '',
        contentEng: '',
      },
      filterStatus: 'active',
    }
  },
  computed: {
    modalTitle() {
      return this.selectedItem ? 'Tahrirlash' : 'Yangi qo\'shish'
    },
    filteredItems() {
      if (!Array.isArray(this.items)) return []

      if (this.filterStatus === 'all') return this.items

      return this.items.filter(item =>
        this.filterStatus === 'deleted' ? Boolean(item.isDeleted) : !item.isDeleted
      )
    },
    activeCount() {
      if (!Array.isArray(this.items)) return 0
      return this.items.filter(item => !item.isDeleted).length
    },
    deletedCount() {
      if (!Array.isArray(this.items)) return 0
      return this.items.filter(item => item.isDeleted).length
    },
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        }

        const response = await axios.get('https://bolajon.mtsh.uz/api/privacy-policy/all', { headers })

        if (response.data) {
          this.items = Array.isArray(response.data) ? response.data : [response.data]
          this.totalRows = this.items.length
        } else {
          this.items = []
          this.totalRows = 0
        }
      } catch (error) {
        this.$bvToast.toast('Ma\'lumotlarni yuklashda xatolik yuz berdi', {
          title: 'Xato',
          variant: 'danger',
          solid: true,
        })
        console.error('Ma\'lumotlarni yuklashda xatolik:', error)
        this.items = []
        this.totalRows = 0
      }
    },

    showAddModal() {
      this.selectedItem = null
      this.isModalVisible = true
    },

    editItem(item) {
      this.selectedItem = item
      this.form = { ...item }
      this.isModalVisible = true
    },

    async handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault();
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        };

        if (this.selectedItem) {
          await this.$http.put(
            `/privacy-policy/update/${this.selectedItem.id}`,
            this.form,
            { headers },
          );
        } else {
          await this.$http.post('/privacy-policy/save', this.form, { headers });
        }

        this.$bvToast.toast('Muvaffaqiyatli saqlandi', {
          title: 'Muvaffaqiyat',
          variant: 'success',
        });
        this.isModalVisible = false;
        this.fetchData();
      } catch (error) {
        this.$bvToast.toast('Saqlashda xatolik yuz berdi', {
          title: 'Xato',
          variant: 'danger',
        });
      }
    },

    confirmDelete(item) {
      this.selectedItem = item
      this.isDeleteModalVisible = true
    },

    async deleteItem() {
      try {
        await this.$http.delete(`/privacy-policy/${this.selectedItem.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        this.$bvToast.toast('Muvaffaqiyatli o\'chirildi', {
          title: 'Muvaffaqiyat',
          variant: 'success',
        })
        this.isDeleteModalVisible = false
        this.fetchData()
      } catch (error) {
        this.$bvToast.toast('O\'chirishda xatolik yuz berdi', {
          title: 'Xato',
          variant: 'danger',
        })
      }
    },

    resetForm() {
      this.form = {
        contentUz: '',
        contentRu: '',
        contentEng: '',
      }
      this.selectedItem = null
    },

    formatDate(dateArray) {
      if (!dateArray) return ''
      const date = new Date(
        dateArray[0],
        dateArray[1] - 1,
        dateArray[2],
        dateArray[3],
        dateArray[4],
        dateArray[5],
      )
      return date.toLocaleString('uz-UZ', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    },
  },
}
</script>

<style lang="scss">
.notification-banner {
  position: relative;
  padding: 1.5rem;
  border-radius: 0.8rem;
  background: linear-gradient(45deg, #1e1b4b 0%, #312e81 50%, #4338ca 100%);
  color: white;
  overflow: hidden;
  margin-bottom: 1.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.5s ease-out;

  // Animatsiyali gradient fon
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.1) 100%
    );
    transform: translateX(-100%);
    animation: gradientSlide 3s ease infinite;
  }

  // To'lqinsimon effekt
  .notification-wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='rgba(255,255,255,0.1)' d='M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,112C672,96,768,96,864,112C960,128,1056,160,1152,160C1248,160,1344,128,1392,112L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: repeat-x;
    animation: waveAnimation 10s linear infinite;
  }

  .notification-content {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 1.2rem;

    .notification-icon {
      background: rgba(255, 255, 255, 0.15);
      padding: 0.8rem;
      border-radius: 50%;
      box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
      animation: iconPulse 2s ease-in-out infinite;

      svg {
        filter: drop-shadow(0 0 5px rgba(255, 215, 0, 0.5));
      }
    }

    .notification-text {
      flex: 1;

      .glow-text {
        font-size: 1.2rem;
        font-weight: 600;
        color: #ffd700;
        text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
        animation: textGlow 2s ease-in-out infinite;
        margin-bottom: 0.5rem;
        display: block;
      }

      .message-text {
        font-size: 0.95rem;
        opacity: 0.9;
        line-height: 1.5;
        animation: fadeIn 0.5s ease-out 0.3s both;
      }
    }
  }
}

// Animatsiyalar
@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes gradientSlide {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes iconPulse {
  0%, 100% {
    transform: scale(1);
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 25px rgba(255, 215, 0, 0.5);
  }
}

@keyframes textGlow {
  0%, 100% {
    text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 215, 0, 0.8),
                 0 0 30px rgba(255, 215, 0, 0.6);
  }
}

@keyframes waveAnimation {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1440px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Status card stillar
.status-card {
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  &.active {
    border: 2px solid #7367f0;
  }

  .status-icon {
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
}

// Table stillar
.table {
  th {
    background: #f8f8f8;
    border-bottom: 2px solid #e8e8e8;
  }

  td {
    vertical-align: middle;
  }
}

// Modal stillar
.modal-content {
  border: none;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

  .modal-header {
    background: #f8f8f8;
    border-bottom: 1px solid #e8e8e8;
  }
}

// Form stillar
.form-control {
  &:focus {
    border-color: #7367f0;
    box-shadow: 0 0 0 0.2rem rgba(115, 103, 240, 0.25);
  }
}
</style>
