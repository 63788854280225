export default [
  {
    title: 'homePage',
    route: 'dashboard-full-statistics',
    icon: 'HomeIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UserIcon',
    action: 'read',
    resource: 'ACL',
    meta: {
      authorities: ["ADMIN"]
    }
  },
  {
    title: 'category',
    route: 'category',
    icon: 'InfoIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'advice',
    route: 'advice',
    icon: 'TwitchIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'development-list',
    route: 'development-list',
    icon: 'SunsetIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'game-blog',
    route: 'game-blog',
    icon: 'SlackIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'Emlash',
    route: 'Vaccination',
    icon: 'PlusSquareIcon',
  },
  {
    title: 'Tibbiy ko‘rik',
    route: 'MedicalExamination',
    icon: 'ActivityIcon',
  },
  {
    title: 'AI',
    route: 'ai',
    icon: 'ServerIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'Biz haqimizda',
    route: 'aboutUs',
    icon: 'SlackIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'So‘rovnoma',
    route: 'survey',
    icon: 'FileTextIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
  {
    title: 'Maxfiylik siyosati',
    route: 'PrivacyPolicy',
    icon: 'ShieldIcon',
  },

  {
    title: 'Bolalar',
    route: 'Childs',
    icon: 'UsersIcon',
  },
  {
    title: 'Xavfsizlik nazorati ',
    route: 'checksecurity',
    icon: 'ServerIcon',
  },
]
