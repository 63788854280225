import Users from '@/views/extensions/users/Users.vue'

const routerView = () => ({
  render(h) {
    return h("router-view");
  }
});

export default [
  // {
  //   path:'/',
  //   title: 'homePage',
  //   route: 'dashboard-full-statistics',
  //   icon: 'HomeIcon',
  //   // acl: {
  //   action: 'read',
  //   resource: 'ACL',
  //   meta: {
  //     authorities: ['ADMIN']
  //   }
  //   // },
  // },

  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      authorities: ['ADMIN']
    }
  },
  {
    path: '/category',
    name: 'category',
    component: () => import('@/views/extensions/category/category.vue'),
    meta: {
      authorities: ['ADMIN','DOCTOR'],
      resource: 'USERS',
      action: 'read',
    },
  },
  {
    path: '/advice-list',
    name: 'advice',
    component: () => import('@/views/extensions/advice/advice-blog.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {

    path: '/advice',
    name: 'advice',
    component: () => import('@/views/extensions/advice/advice.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    },
  },
  {
    title: 'development',
    route: 'development',
    icon: 'SunsetIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    path: '/development',
    name: 'development',
    component: () => import('@/views/extensions/development/development.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {
    title: 'development-list',
    route: 'development-list',
    icon: 'SunsetIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    path: '/development-list',
    name: 'development-list',
    component: () => import('@/views/extensions/development/development-list.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {
    title: 'AI',
    route: 'ai',
    icon: 'SunsetIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    path: '/ai',
    name: 'ai',
    component: () => import('@/views/extensions/AI/historyAI.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {
    title: 'aboutUs',
    route: 'aboutUs',
    icon: 'SunsetIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/extensions/aboutUs/aboutUs.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {
    title: 'survey',
    route: 'survey',
    icon: 'SunsetIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    path: '/survey',
    name: 'survey',
    component: () => import('@/views/extensions/survey/survey.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {
    title: 'PrivacyPolicy',
    route: 'PrivacyPolicy',
    icon: 'SunsetIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/extensions/privacyPolicy/privacyPolicy.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {
    title: 'Vaccination',
    route: 'Vaccination',
    icon: 'SunsetIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    path: '/Vaccination',
    name: 'Vaccination',
    component: () => import('@/views/extensions/emlash/emlash.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {
    title: 'MedicalExamination',
    route: 'MedicalExamination',
    icon: 'SunsetIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    path: '/MedicalExamination',
    name: 'MedicalExamination',
    component: () => import('@/views/extensions/tibbiy-korik/tibbiy-korik.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {
    title: 'Childs',
    route: 'Childs',
    icon: 'SunsetIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    path: '/Childs',
    name: 'Childs',
    component: () => import('@/views/extensions/child/child.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {
    title: 'checksecurity',
    route: 'checksecurity',
    icon: 'SunsetIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    path: '/checksecurity',
    name: 'checksecurity',
    component: () => import('@/views/extensions/check-security/checksecurity.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},

  {
    title: 'game',
    route: 'game',
    icon: 'SlackIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    path: '/game',
    name: 'game',
    component: () => import('@/views/extensions/game/game.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {
    title: 'game-blog',
    route: 'game-blog',
    icon: 'SlackIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    path: '/game-blog',
    name: 'game-blog',
    component: () => import('@/views/extensions/game/game-blog.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
]
