<template>
  <div class="tibbiy-korik-container">
    <!-- Tibbiy ko'rik uchun modal -->
    <b-modal
      v-model="isCategoryModalVisible"
      :title="selectedCategory ? 'Tibbiy ko\'rikni tahrirlash' : 'Yangi tibbiy ko\'rik'"
      @hidden="resetCategoryForm"
      @ok="handleCategorySubmit"
    >
      <b-form>
        <b-form-group label="Nomi (UZ)">
          <b-form-input v-model="categoryForm.nameUz" required />
        </b-form-group>
        <b-form-group label="Nomi (RU)">
          <b-form-input v-model="categoryForm.nameRu" required />
        </b-form-group>
        <b-form-group label="Nomi (EN)">
          <b-form-input v-model="categoryForm.nameEn" required />
        </b-form-group>
        <b-form-group label="Holati">
          <b-form-checkbox v-model="categoryForm.isActive">Faol</b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-card no-body>
      <!-- Header section -->
      <div class="modern-header">
        <div class="d-flex justify-content-between align-items-center flex-wrap gap-2">
          <div class="header-title">
            <h3 class="mb-0">Tibbiy ko'rik ma'lumotlari</h3>
          </div>
          <div class="header-actions d-flex gap-2">
            <b-button
              variant="primary"
              class="d-flex align-items-center"
              @click="showCategoryModal"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-2"
              />
              <span>Yangi tibbiy ko'rik</span>
            </b-button>
          </div>
        </div>
      </div>

      <b-card-body>
        <!-- Tibbiy ko'riklar jadvali -->
        <b-table
          :items="categories"
          :fields="categoryFields"
          responsive
          striped
          hover
        >
          <template #cell(isActive)="row">
            <b-badge :variant="row.item.isActive === 1 ? 'success' : 'danger'">
              {{ row.item.isActive === 1 ? 'Faol' : 'Faol emas' }}
            </b-badge>
          </template>

          <template #cell(actions)="row">
            <b-button-group size="sm">
              <b-button
                variant="primary"
                @click="editCategory(row.item)"
              >
                <feather-icon icon="EditIcon" size="16"/>
              </b-button>
              <b-button
                variant="danger"
                @click="deleteCategory(row.item)"
              >
                <feather-icon icon="TrashIcon" size="16"/>
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BButton,
  BTable,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButtonGroup,
  BBadge,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  name: 'TibbiyKorik',
  components: {
    BCard,
    BCardBody,
    BButton,
    BTable,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButtonGroup,
    BBadge,
  },
  data() {
    return {
      isCategoryModalVisible: false,
      selectedCategory: null,
      categoryForm: {
        nameUz: '',
        nameRu: '',
        nameEn: '',
        isActive: 1,
        isDeleted: 0,
      },
      categories: [],

      // Jadval ustunlari
      categoryFields: [
        { key: 'id', label: 'ID', tdClass: 'text-center' },
        { key: 'nameUz', label: 'Nomi (UZ)' },
        { key: 'nameRu', label: 'Nomi (RU)' },
        { key: 'nameEn', label: 'Nomi (EN)' },
        { key: 'isActive', label: 'Holati' },
        { key: 'actions', label: 'Amallar', tdClass: 'text-right' },
      ],
    }
  },
  async created() {
    await this.fetchCategories()
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await this.$http.get('/tibbiy-category/find-all-list', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        this.categories = response.data
      } catch (error) {
        console.error('Tibbiy ko\'riklarni yuklashda xatolik:', error)
        this.$bvToast.toast('Ma\'lumotlarni yuklashda xatolik yuz berdi', {
          title: 'Xato',
          variant: 'danger',
        })
      }
    },

    async handleCategorySubmit() {
      try {
        const payload = {
          ...this.categoryForm,
          isActive: this.categoryForm.isActive ? 1 : 0,
          isDeleted: 0,
        }

        if (this.selectedCategory?.id) {
          payload.id = this.selectedCategory.id
        }

        await this.$http.post('/tibbiy-category/save', payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        this.isCategoryModalVisible = false
        await this.fetchCategories()

        this.$bvToast.toast(
          this.selectedCategory ? 'Tibbiy ko\'rik muvaffaqiyatli yangilandi' : 'Yangi tibbiy ko\'rik muvaffaqiyatli qo\'shildi',
          {
            title: 'Muvaffaqiyat',
            variant: 'success',
          },
        )

        this.resetCategoryForm()
      } catch (error) {
        console.error('Saqlashda xatolik:', error.response?.data || error)
        this.$bvToast.toast(error.response?.data?.message || 'Xatolik yuz berdi', {
          title: 'Xato',
          variant: 'danger',
        })
      }
    },

    showCategoryModal() {
      this.selectedCategory = null
      this.resetCategoryForm()
      this.isCategoryModalVisible = true
    },

    resetCategoryForm() {
      this.categoryForm = {
        nameUz: '',
        nameRu: '',
        nameEn: '',
        isActive: 1,
        isDeleted: 0,
      }
      this.selectedCategory = null
    },

    editCategory(item) {
      this.selectedCategory = item
      this.categoryForm = {
        id: item.id,
        nameUz: item.nameUz,
        nameRu: item.nameRu,
        nameEn: item.nameEn,
        isActive: item.isActive === 1,
        isDeleted: item.isDeleted,
      }
      this.isCategoryModalVisible = true
    },

    async deleteCategory(item) {
      try {
        const result = await this.$bvModal.msgBoxConfirm('Haqiqatan ham bu tibbiy ko\'rikni o\'chirmoqchimisiz?', {
          title: 'O\'chirishni tasdiqlash',
          okVariant: 'danger',
          okTitle: 'O\'chirish',
          cancelTitle: 'Bekor qilish',
          hideHeaderClose: false,
          centered: true,
        })

        if (result) {
          const payload = {
            id: item.id,
            nameUz: item.nameUz,
            nameRu: item.nameRu,
            nameEn: item.nameEn,
            isActive: item.isActive,
            isDeleted: 1,
          }

          await this.$http.post('/tibbiy-category/save', payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })

          await this.fetchCategories()
          this.$bvToast.toast('Tibbiy ko\'rik muvaffaqiyatli o\'chirildi', {
            title: 'Muvaffaqiyat',
            variant: 'success',
          })
        }
      } catch (error) {
        console.error('O\'chirishda xatolik:', error.response?.data || error)
        this.$bvToast.toast('Tibbiy ko\'rikni o\'chirishda xatolik yuz berdi', {
          title: 'Xato',
          variant: 'danger',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tibbiy-korik-container {
  background: #f8fafc;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

// Header stillar
.modern-header {
  background: linear-gradient(to right, #7367f0, #9e95f5);
  padding: 1.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
  position: relative;
  overflow: hidden;

  .header-title h3 {
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

// Gap utility
.gap-2 {
  gap: 0.5rem;
}

// Jadval stillar
.table {
  border-collapse: separate;
  border-spacing: 0 0.5rem;

  thead th {
    background: #f8f9fa;
    border: none;
    padding: 1rem;
    font-weight: 600;
    color: #5e5873;
    text-transform: uppercase;
    font-size: 0.857rem;
    letter-spacing: 0.5px;
  }

  tbody tr {
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    td {
      border: none;
      padding: 1rem;
      vertical-align: middle;
      color: #6e6b7b;
    }
  }
}

// Badge stillar
.badge {
  padding: 0.5rem 0.8rem;
  font-size: 0.9rem;
  font-weight: 500;

  &.badge-success {
    background-color: #28c76f;
    color: white;
  }

  &.badge-danger {
    background-color: #ea5455;
    color: white;
  }
}
</style>
