<template>
  <div>
    <!-- Animatsiyali ogohlantirish -->
    <div class="notification-banner mb-4">
      <div class="notification-glow"></div>
      <div class="notification-content">
        <div class="notification-icon pulse">
          <feather-icon icon="AlertCircleIcon" size="20" class="text-warning" />
        </div>
        <div class="notification-text">
          <strong class="glow-text">Diqqat!</strong>
          <span class="message-text">Bir vaqtning o'zida faqat bitta URL faol bo'lishi mumkin</span>
        </div>
      </div>
      <div class="notification-wave"></div>
    </div>

    <!-- Statistika kartasi -->
    <b-card class="mb-4 statistics-card">
      <div class="d-flex justify-content-between align-items-center">
        <!-- Jami URLlar -->
        <div class="stat-box cursor-pointer" @click="filterByAll()">
          <div class="d-flex align-items-center">
            <div class="stat-icon total-bg" :class="{ 'active-filter': currentFilter === 'all' }">
              <feather-icon icon="GlobeIcon" size="22" class="text-white" />
            </div>
            <div class="stat-info">
              <span class="stat-label">Jami URLlar</span>
              <h4 class="stat-value mb-0">{{ statistics.total }}</h4>
            </div>
          </div>
        </div>

        <div class="stat-divider"></div>

        <!-- Faol URLlar -->
        <div class="stat-box cursor-pointer" @click="filterByActive()">
          <div class="d-flex align-items-center">
            <div class="stat-icon active-bg" :class="{ 'active-filter': currentFilter === 'active' }">
              <feather-icon icon="CheckCircleIcon" size="22" class="text-white" />
            </div>
            <div class="stat-info">
              <span class="stat-label">Faol URLlar</span>
              <h4 class="stat-value mb-0 text-success">{{ statistics.active }}</h4>
            </div>
          </div>
        </div>

        <div class="stat-divider"></div>

        <!-- O'chirilgan URLlar -->
        <div class="stat-box cursor-pointer" @click="filterByDeleted()">
          <div class="d-flex align-items-center">
            <div class="stat-icon deleted-bg" :class="{ 'active-filter': currentFilter === 'deleted' }">
              <feather-icon icon="TrashIcon" size="22" class="text-white" />
            </div>
            <div class="stat-info">
              <span class="stat-label">O'chirilgan</span>
              <h4 class="stat-value mb-0 text-danger">{{ statistics.deleted }}</h4>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <b-card class="mb-4">
      <b-card-title>
        <feather-icon icon="PlusCircleIcon" class="text-primary mr-2" />
        Yangi URL qo'shish
      </b-card-title>
      <b-card-body>
        <b-form @submit.prevent="saveSurvey">
          <b-form-group>
            <b-form-input
              v-model="survey.url"
              placeholder="https://example.com"
              :state="urlValidation"
              required
            ></b-form-input>
            <b-form-invalid-feedback>
              URL to'g'ri formatda kiritilishi kerak
            </b-form-invalid-feedback>
          </b-form-group>
          <b-button
            type="submit"
            variant="primary"
            class="mr-2"
            :disabled="!urlValidation"
            :loading="saving"
          >
            <feather-icon icon="SaveIcon" size="16" class="mr-1" />
            Saqlash
          </b-button>
          <b-button variant="outline-secondary" @click="resetForm">
            Tozalash
          </b-button>
        </b-form>
      </b-card-body>
    </b-card>

    <b-card>
      <b-card-title>
        <feather-icon icon="ListIcon" class="text-primary mr-2" />
        URLlar ro'yxati
        <b-form-input
          v-model="search"
          placeholder="Qidirish..."
          class="w-25 float-right"
        ></b-form-input>
      </b-card-title>
      <b-card-body>
        <b-table
          :items="surveys"
          :fields="fields"
          :busy="loading"
          responsive
          striped
          hover
          :tbody-tr-class="rowClass"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <!-- URL ustuni -->
          <template #cell(url)="data">
            <a :href="data.item.url" target="_blank" :class="{'text-muted': data.item.isDeleted}">
              {{ data.item.url }}
            </a>
          </template>

          <!-- Status ustuni -->
          <template #cell(status)="data">
            <b-badge :variant="data.item.isDeleted ? 'danger' : 'success'">
              {{ data.item.isDeleted ? 'O\'chirilgan' : 'Faol' }}
            </b-badge>
          </template>

          <!-- Amallar ustuni -->
          <template #cell(actions)="data">
            <template v-if="!data.item.isDeleted">
              <b-button
                variant="danger"
                size="sm"
                @click="confirmDelete(data.item)"
                v-b-tooltip.hover
                title="O'chirish"
              >
                <feather-icon icon="TrashIcon" size="16" />
              </b-button>
            </template>
            <template v-else>
              <b-badge variant="danger" class="d-flex align-items-center">
                <feather-icon icon="XCircleIcon" size="16" class="mr-1" />
                O'chirilgan
              </b-badge>
            </template>
          </template>
        </b-table>

        <!-- Pagination qo'shamiz -->
        <div class="mt-3 d-flex justify-content-between align-items-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            class="mb-0"
            @input="handlePageChange"
          ></b-pagination>

          <b-form-select
            v-model="perPage"
            :options="pageOptions"
            class="w-25"
            @input="handlePerPageChange"
          ></b-form-select>
        </div>
      </b-card-body>
    </b-card>

    <b-modal
      v-model="deleteDialog"
      title="O'chirishni tasdiqlash"
      header-bg-variant="danger"
      header-text-variant="light"
      ok-variant="danger"
      ok-title="O'chirish"
      cancel-title="Bekor qilish"
      @ok="deleteSurvey"
    >
      <p class="my-2">Rostdan ham bu URLni o'chirmoqchimisiz?</p>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BCard,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BTable,
  BModal,
  VBTooltip,
  BPagination,
  BFormSelect,
} from 'bootstrap-vue'

// Feather icon import yo'lini to'g'irlaymiz
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  name: 'Survey',
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BTable,
    BModal,
    FeatherIcon,
    BPagination,
    BFormSelect,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      loading: false,
      saving: false,
      search: '',
      deleteDialog: false,
      selectedItem: null,
      survey: {
        url: ''
      },
      surveys: [],
      statistics: {
        total: 0,
        active: 0,
        deleted: 0,
      },
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'url', label: 'URL manzili', sortable: true },
        {
          key: 'createdDate',
          label: 'Yaratilgan sana',
          sortable: true,
          formatter: value => this.formatDate(value),
        },
        {
          key: 'status',
          label: 'Holati',
          sortable: true,
        },
        { key: 'actions', label: 'Amallar' },
      ],
      perPage: 10,
      currentPage: 1,
      sortBy: 'createdDate',
      sortDesc: true,
      pageOptions: [5, 10, 15, 20, 50],
      currentFilter: 'all',
      allSurveys: [],
    }
  },
  computed: {
    urlValidation() {
      return /^https?:\/\/.+\..+/.test(this.survey.url)
    },
    totalRows() {
      return this.surveys.length
    },
  },
  mounted() {
    this.loadSurveys()
  },
  methods: {
    formatDate(dateArray) {
      if (!dateArray) return ''
      const [year, month, day, hour, minute] = dateArray
      return `${day}.${month}.${year} ${hour}:${minute}`
    },
    async loadSurveys() {
      this.loading = true
      try {
        const token = localStorage.getItem('accessToken')
        const response = await axios.get('https://bolajon.mtsh.uz/api/survey/all', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const surveysArray = Array.isArray(response.data) ? response.data : [response.data]

        this.allSurveys = surveysArray.map(item => ({
          id: item.id,
          url: item.url,
          createdDate: item.createdDate,
          isDeleted: item.isDeleted,
        }))

        // Joriy filter bo'yicha ma'lumotlarni ko'rsatish
        if (this.currentFilter === 'active') {
          this.filterByActive()
        } else if (this.currentFilter === 'deleted') {
          this.filterByDeleted()
        } else {
          this.filterByAll()
        }

        this.updateStatistics()
      } catch (error) {
        console.error('URLlarni yuklashda xatolik:', error)
        this.allSurveys = []
        this.surveys = []
      }
      this.loading = false
    },
    async saveSurvey() {
      if (!this.urlValidation) return

      this.saving = true
      try {
        const token = localStorage.getItem('accessToken')
        await this.$http.post('/survey/save', this.survey, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
        this.resetForm()
        this.loadSurveys()
        this.$bvToast.toast('URL muvaffaqiyatli saqlandi!', {
          title: 'Muvaffaqiyatli',
          variant: 'success'
        })
      } catch (error) {
        console.error('URLni saqlashda xatolik:', error)
        this.$bvToast.toast('URLni saqlashda xatolik yuz berdi', {
          title: 'Xatolik',
          variant: 'danger'
        })
      }
      this.saving = false
    },
    confirmDelete(item) {
      this.selectedItem = item
      this.deleteDialog = true
    },
    async deleteSurvey() {
      try {
        const token = localStorage.getItem('accessToken')

        // DELETE so'rovini yuborish
        const response = await axios({
          method: 'DELETE',
          url: `https://bolajon.mtsh.uz/api/survey/${this.selectedItem.id}`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })

        if (response.status === 200) {
          // Muvaffaqiyatli o'chirildi
          await this.loadSurveys()
          this.$bvToast.toast('URL muvaffaqiyatli o\'chirildi!', {
            title: 'Muvaffaqiyatli',
            variant: 'success',
          })
        } else {
          throw new Error('O\'chirish muvaffaqiyatsiz yakunlandi')
        }
      } catch (error) {
        console.error('URLni o\'chirishda xatolik:', error)
        this.$bvToast.toast(
          error.response?.data?.message || 'URLni o\'chirishda xatolik yuz berdi',
          {
            title: 'Xatolik',
            variant: 'danger',
          }
        )
      } finally {
        this.deleteDialog = false
        this.selectedItem = null
      }
    },
    async restoreItem(item) {
      try {
        const token = localStorage.getItem('accessToken')
        await this.$http.post(`/survey/restore/${item.id}`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        })
        await this.loadSurveys()
        this.$bvToast.toast('URL muvaffaqiyatli tiklandi!', {
          title: 'Muvaffaqiyatli',
          variant: 'success',
        })
      } catch (error) {
        console.error('URLni tiklashda xatolik:', error)
        this.$bvToast.toast('URLni tiklashda xatolik yuz berdi', {
          title: 'Xatolik',
          variant: 'danger',
        })
      }
    },
    resetForm() {
      this.survey = {
        url: ''
      }
    },
    updateStatistics() {
      this.statistics = {
        total: this.surveys.length,
        active: this.surveys.filter(item => !item.isDeleted).length,
        deleted: this.surveys.filter(item => item.isDeleted).length,
      }
    },
    rowClass(item) {
      return item.isDeleted ? 'table-danger' : ''
    },
    handlePageChange(value) {
      this.currentPage = value
    },
    handlePerPageChange(value) {
      this.perPage = value
      this.currentPage = 1
    },
    filterByAll() {
      this.currentFilter = 'all'
      this.surveys = this.allSurveys
      this.currentPage = 1
    },
    filterByActive() {
      this.currentFilter = 'active'
      this.surveys = this.allSurveys.filter(item => !item.isDeleted)
      this.currentPage = 1
    },
    filterByDeleted() {
      this.currentFilter = 'deleted'
      this.surveys = this.allSurveys.filter(item => item.isDeleted)
      this.currentPage = 1
    },
  }
}
</script>

<style scoped>
.table th {
  font-weight: bold;
}

.text-muted {
  text-decoration: line-through;
}

.table-danger {
  background-color: rgba(255, 0, 0, 0.1) !important;
}

/* Yangi statistika stillari */
.statistics-card {
  background: #ffffff;
  border: none;
  box-shadow: 0 0 20px rgba(0,0,0,0.05);
}

.stat-box {
  padding: 0.5rem 1rem;
}

.stat-divider {
  width: 1px;
  height: 40px;
  background: linear-gradient(to bottom, transparent, #e0e0e0, transparent);
}

.stat-icon {
  width: 42px;
  height: 42px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  transition: all 0.3s ease;
}

.stat-icon i {
  font-size: 1.2rem;
  color: white;
}

.total-bg {
  background: linear-gradient(135deg, #4f46e5, #6366f1);
}

.active-bg {
  background: linear-gradient(135deg, #059669, #10b981);
}

.deleted-bg {
  background: linear-gradient(135deg, #dc2626, #ef4444);
}

.stat-info {
  display: flex;
  flex-direction: column;
}

.stat-label {
  font-size: 0.8rem;
  color: #6b7280;
  margin-bottom: 2px;
}

.stat-value {
  font-size: 1.25rem;
  font-weight: 600;
}

/* Qolgan stillar o'zgarishsiz... */

/* Pagination uchun qo'shimcha stillar */
.pagination {
  margin-bottom: 0;
}

.page-item.active .page-link {
  background-color: #4f46e5;
  border-color: #4f46e5;
}

.page-link {
  color: #4f46e5;
}

.page-link:hover {
  color: #6366f1;
}

.per-page-select {
  width: 80px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover .stat-icon {
  transform: scale(1.05);
}

.active-filter {
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
}

/* Hover effektlari */
.stat-box:hover {
  background: rgba(0,0,0,0.02);
  border-radius: 8px;
}

.stat-box {
  transition: all 0.3s ease;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

/* Yangi animatsiyali ogohlantirish stillari */
.notification-banner {
  background: linear-gradient(135deg, #1a1f35, #2d3558);
  border: none;
  padding: 1.2rem;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
  z-index: 1;
}

.notification-glow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 50%, rgba(255, 182, 24, 0.15), transparent 60%);
  animation: glow 4s infinite;
  top: 0;
  left: 0;
}

.notification-content {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 15px;
}

.notification-icon {
  background: rgba(255, 182, 24, 0.15);
  padding: 10px;
  border-radius: 50%;
  position: relative;
}

.pulse {
  animation: pulse 2s infinite;
}

.notification-text {
  color: #fff;
  font-size: 1rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.glow-text {
  color: #ffd700;
  font-weight: 700;
  position: relative;
  animation: textGlow 2s infinite;
}

.message-text {
  opacity: 0.9;
  animation: fadeInOut 4s infinite;
}

.notification-wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, #ffd700, #ff6b6b, #4f46e5);
  animation: waveMove 2s infinite linear;
}

/* Animatsiyalar */
@keyframes glow {
  0%, 100% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 182, 24, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(255, 182, 24, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 182, 24, 0);
  }
}

@keyframes textGlow {
  0%, 100% {
    text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 215, 0, 0.8),
                 0 0 30px rgba(255, 215, 0, 0.6),
                 0 0 40px rgba(255, 215, 0, 0.4);
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
}

@keyframes waveMove {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100vw 0;
  }
}

/* Hover effekti */
.notification-banner:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.notification-banner:hover .notification-glow {
  animation: glow 2s infinite;
}

.notification-banner:hover .notification-wave {
  height: 8px;
  animation: waveMove 1s infinite linear;
}

/* Qo'shimcha effektlar */
.notification-banner::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, transparent 60%);
  transform: rotate(0deg);
  animation: rotate 10s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
