<template>
  <b-row class="justify-content-center">
    <b-col cols="12">
      <!-- Animatsiyali ogohlantirish -->
      <div class="notification-banner mb-4">
        <div class="notification-glow"></div>
        <div class="notification-content">
          <div class="notification-icon pulse">
            <feather-icon icon="AlertTriangleIcon" size="20" class="text-warning" />
          </div>
          <div class="notification-text">
            <strong class="glow-text">Muhim eslatma!</strong>
            <span class="message-text"> Tashkilot haqidagi ma'lumotlar yagona va ishonchli bo'lishi uchun bir vaqtning o'zida faqat bitta ma'lumot faol holatda saqlanadi. Yangi ma'lumot kiritilganda, avvalgi faol ma'lumot avtomatik o'chirilishi lozim bo'ladi.</span>
          </div>
        </div>
        <div class="notification-wave"></div>
      </div>

      <b-card>
        <!-- Yuqori qism - Statistika -->
        <b-row class="mb-2">
          <b-col md="4" sm="12">
            <b-card
              bg-variant="light"
              class="status-card cursor-pointer"
              :class="{ active: filterStatus === 'active' }"
              @click="filterStatus = 'active'"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="mb-1">Faol</h6>
                  <h3 class="mb-0 text-success">{{ activeCount }}</h3>
                </div>
                <div class="status-icon bg-success-light">
                  <feather-icon icon="CheckCircleIcon" class="text-success"/>
                </div>
              </div>
            </b-card>
          </b-col>

          <b-col md="4" sm="12">
            <b-card
              bg-variant="light"
              class="status-card cursor-pointer"
              :class="{ active: filterStatus === 'deleted' }"
              @click="filterStatus = 'deleted'"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="mb-1">O'chirilgan</h6>
                  <h3 class="mb-0 text-danger">{{ deletedCount }}</h3>
                </div>
                <div class="status-icon bg-danger-light">
                  <feather-icon icon="ArchiveIcon" class="text-danger"/>
                </div>
              </div>
            </b-card>
          </b-col>

          <b-col md="4" sm="12">
            <b-card
              bg-variant="light"
              class="status-card cursor-pointer"
              :class="{ active: filterStatus === 'all' }"
              @click="filterStatus = 'all'"
            >
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <h6 class="mb-1">Jami</h6>
                  <h3 class="mb-0 text-primary">{{ totalRows }}</h3>
                </div>
                <div class="status-icon bg-primary-light">
                  <feather-icon icon="LayersIcon" class="text-primary"/>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <!-- Qo'shish tugmasi -->
        <div class="d-flex justify-content-end mb-2">
          <b-button
            variant="primary"
            @click="showAddModal"
          >
            <feather-icon icon="PlusIcon" class="mr-1"/> Yangi qo'shish
          </b-button>
        </div>

        <!-- Ma'lumotlar jadvali -->
        <b-table
          :items="filteredItems"
          :fields="fields"
          responsive="sm"
          hover
          striped
          show-empty
        >
          <template #cell(status)="data">
            <b-badge :variant="data.item.isDeleted ? 'danger' : 'success'">
              {{ data.item.isDeleted ? 'O\'chirilgan' : 'Faol' }}
            </b-badge>
          </template>

          <template #cell(createdDate)="data">
            {{ formatDate(data.item.createdDate) }}
          </template>

          <template #cell(actions)="row">
            <b-button-group size="sm">
              <b-button
                v-if="!row.item.isDeleted"
                variant="outline-primary"
                @click="editItem(row.item)"
              >
                <feather-icon icon="EditIcon" size="16"/>
              </b-button>
              <b-button
                v-if="!row.item.isDeleted"
                variant="outline-danger"
                @click="confirmDelete(row.item)"
              >
                <feather-icon icon="TrashIcon" size="16"/>
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          class="my-0"
        />
      </b-card>
    </b-col>

    <!-- Qo'shish/Tahrirlash modali -->
    <b-modal
      v-model="isModalVisible"
      :title="modalTitle"
      size="lg"
      @hidden="resetForm"
      @ok="handleSubmit"
    >
      <b-tabs content-class="mt-3">
        <!-- O'zbekcha tab -->
        <b-tab title="O'zbekcha" active>
          <b-form-group label="Matn (UZ)">
            <b-form-textarea
              v-model="form.contentUz"
              rows="6"
              placeholder="O'zbekcha matnni kiriting"
            />
          </b-form-group>
        </b-tab>

        <!-- Ruscha tab -->
        <b-tab title="Русский">
          <b-form-group label="Текст (RU)">
            <b-form-textarea
              v-model="form.contentRu"
              rows="6"
              placeholder="Введите текст на русском"
            />
          </b-form-group>
        </b-tab>

        <!-- Inglizcha tab -->
        <b-tab title="English">
          <b-form-group label="Text (ENG)">
            <b-form-textarea
              v-model="form.contentEng"
              rows="6"
              placeholder="Enter text in English"
            />
          </b-form-group>
        </b-tab>
      </b-tabs>
    </b-modal>

    <!-- O'chirish modali -->
    <b-modal
      v-model="isDeleteModalVisible"
      title="O'chirishni tasdiqlash"
      @ok="deleteItem"
    >
      <p>Haqiqatan ham bu ma'lumotni o'chirmoqchimisiz?</p>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BButton,
  BButtonGroup, BModal, BTabs, BTab, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BButton,
    BButtonGroup,
    BModal,
    BTabs,
    BTab,
    BFormGroup,
    BFormTextarea,
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'contentUz', label: 'O\'zbekcha' },
        { key: 'contentRu', label: 'Русский' },
        { key: 'contentEng', label: 'English' },

        { key: 'createdDate', label: 'Yaratilgan sana' },
        { key: 'actions', label: 'Amallar' },
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      isModalVisible: false,
      isDeleteModalVisible: false,
      selectedItem: null,
      form: {
        contentUz: '',
        contentRu: '',
        contentEng: '',
      },
      filterStatus: 'active',
    }
  },
  computed: {
    modalTitle() {
      return this.selectedItem ? 'Tahrirlash' : 'Yangi qo\'shish'
    },
    filteredItems() {
      if (this.filterStatus === 'all') return this.items
      return this.items.filter(item =>
        this.filterStatus === 'deleted' ? item.isDeleted : !item.isDeleted
      )
    },
    activeCount() {
      return this.items.filter(item => !item.isDeleted).length
    },
    deletedCount() {
      return this.items.filter(item => item.isDeleted).length
    },
  },
  created() {
    axios.interceptors.request.use(config => {
      const token = localStorage.getItem('accessToken')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    }, error => {
      return Promise.reject(error)
    })

    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get('https://bolajon.mtsh.uz/api/about-us/all', {
          params: {
            page: this.currentPage - 1,
            size: this.perPage,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        this.items = response.data.data.content
        this.totalRows = response.data.data.totalElements
      } catch (error) {
        if (error.response?.status === 401) {
          this.$bvToast.toast('Avtorizatsiyadan o\'tilmagan', {
            title: 'Xato',
            variant: 'warning',
          })
        } else {
          this.$bvToast.toast('Ma\'lumotlarni yuklashda xatolik yuz berdi', {
            title: 'Xato',
            variant: 'danger',
          })
        }
      }
    },

    showAddModal() {
      this.selectedItem = null
      this.isModalVisible = true
    },

    editItem(item) {
      this.selectedItem = item
      this.form = { ...item }
      this.isModalVisible = true
    },

    async handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      try {
        const headers = {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }

        if (this.selectedItem) {
          await this.$http.put('/about-us/update', {
            ...this.form,
            id: this.selectedItem.id,
          }, { headers })
        } else {
          await this.$http.post('/about-us/save', this.form, { headers })
        }

        this.$bvToast.toast('Muvaffaqiyatli saqlandi', {
          title: 'Muvaffaqiyat',
          variant: 'success',
        })
        this.isModalVisible = false
        this.fetchData()
      } catch (error) {
        if (error.response?.status === 401) {
          this.$bvToast.toast('Avtorizatsiyadan o\'tilmagan', {
            title: 'Xato',
            variant: 'warning',
          })
        } else {
          this.$bvToast.toast('Saqlashda xatolik yuz berdi', {
            title: 'Xato',
            variant: 'danger',
          })
        }
      }
    },

    confirmDelete(item) {
      this.selectedItem = item
      this.isDeleteModalVisible = true
    },

    async deleteItem() {
      try {
        await this.$http.delete(`/about-us/${this.selectedItem.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        this.$bvToast.toast('Muvaffaqiyatli o\'chirildi', {
          title: 'Muvaffaqiyat',
          variant: 'success',
        })
        this.isDeleteModalVisible = false
        this.fetchData()
      } catch (error) {
        if (error.response?.status === 401) {
          this.$bvToast.toast('Avtorizatsiyadan o\'tilmagan', {
            title: 'Xato',
            variant: 'warning',
          })
        } else {
          this.$bvToast.toast('O\'chirishda xatolik yuz berdi', {
            title: 'Xato',
            variant: 'danger',
          })
        }
      }
    },

    resetForm() {
      this.form = {
        contentUz: '',
        contentRu: '',
        contentEng: '',
      }
      this.selectedItem = null
    },

    formatDate(dateArray) {
      if (!dateArray) return ''
      const date = new Date(
        dateArray[0],
        dateArray[1] - 1,
        dateArray[2],
        dateArray[3],
        dateArray[4],
        dateArray[5],
      )
      return date.toLocaleString('uz-UZ', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
    },

    async restoreItem(item) {
      try {
        await axios.put('https://bolajon.mtsh.uz/api/about-us/update', {
          ...item,
          isDeleted: false,
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        this.$bvToast.toast('Muvaffaqiyatli qayta tiklandi', {
          title: 'Muvaffaqiyat',
          variant: 'success',
        })
        this.fetchData()
      } catch (error) {
        if (error.response?.status === 401) {
          this.$bvToast.toast('Avtorizatsiyadan o\'tilmagan', {
            title: 'Xato',
            variant: 'warning',
          })
        } else {
          this.$bvToast.toast('Qayta tiklashda xatolik yuz berdi', {
            title: 'Xato',
            variant: 'danger',
          })
        }
      }
    },
  },
}
</script>

<style scoped>
.card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

.table th {
  font-weight: 600;
  background-color: #f8f8f8;
}

.btn-group {
  gap: 0.5rem;
}

.nav-tabs {
  border-bottom: 2px solid #f8f8f8;
}

.nav-tabs .nav-link.active {
  color: #7367f0;
  border-bottom: 2px solid #7367f0;
}

.form-control:focus {
  border-color: #7367f0;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
}

.status-card {
  transition: all 0.3s ease;
  cursor: pointer;
  border: 2px solid transparent;
}

.status-card:hover {
  transform: translateY(-5px);
}

.status-card.active {
  border-color: #7367f0;
  background-color: #f8f8ff !important;
}

.status-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-success-light {
  background-color: rgba(40, 199, 111, 0.12);
}

.bg-danger-light {
  background-color: rgba(234, 84, 85, 0.12);
}

.bg-primary-light {
  background-color: rgba(115, 103, 240, 0.12);
}

.badge {
  padding: 0.5rem 0.75rem;
  font-size: 0.85rem;
}

.cursor-pointer {
  cursor: pointer;
}

.table td {
  vertical-align: middle;
}

/* Yangi animatsiyali ogohlantirish stillari */
.notification-banner {
  background: linear-gradient(135deg, #1e1b4b, #312e81);
  border: none;
  padding: 1.2rem;
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
  z-index: 1;
}

.notification-glow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 50%, rgba(129, 140, 248, 0.15), transparent 60%);
  animation: glow 4s infinite;
  top: 0;
  left: 0;
}

.notification-content {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 15px;
}

.notification-icon {
  background: rgba(129, 140, 248, 0.15);
  padding: 10px;
  border-radius: 50%;
  position: relative;
}

.pulse {
  animation: pulse 2s infinite;
}

.notification-text {
  color: #fff;
  font-size: 1rem;
  line-height: 1.6;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.glow-text {
  color: #818cf8;
  font-weight: 700;
  position: relative;
  animation: textGlow 2s infinite;
}

.message-text {
  opacity: 0.9;
  animation: fadeInOut 4s infinite;
}

.notification-wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, #818cf8, #6366f1, #4f46e5);
  animation: waveMove 2s infinite linear;
}

@keyframes glow {
  0%, 100% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(129, 140, 248, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(129, 140, 248, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(129, 140, 248, 0);
  }
}

@keyframes textGlow {
  0%, 100% {
    text-shadow: 0 0 10px rgba(129, 140, 248, 0.5);
  }
  50% {
    text-shadow: 0 0 20px rgba(129, 140, 248, 0.8),
                 0 0 30px rgba(129, 140, 248, 0.6),
                 0 0 40px rgba(129, 140, 248, 0.4);
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
}

@keyframes waveMove {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100vw 0;
  }
}

.notification-banner:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.notification-banner:hover .notification-glow {
  animation: glow 2s infinite;
}

.notification-banner:hover .notification-wave {
  height: 8px;
  animation: waveMove 1s infinite linear;
}

.notification-banner::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 0%, transparent 60%);
  transform: rotate(0deg);
  animation: rotate 10s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
