<template>
  <b-card class="earnings-card">
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1">Chatbot orqali murojaatlar</b-card-title>
        <div class="font-small-2">Jami</div>
        <h5 class="mb-1">{{ statistics.total || 0 }}</h5>
        <b-card-text class="text-muted font-small-2">
          <span>Kunlik murojaatlar soni: </span>
          <span class="font-weight-bolder">{{ statistics.today || 0 }} TA</span>
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <vue-apex-charts
          height="120"
          :options="earningsChart.chartOptions"
          :series="chartSeries"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import axios from '@axios'

const $earningsStrokeColor2 = '#28c76f66'
const $earningsStrokeColor3 = '#28c76f33'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },

  data() {
    return {
      statistics: {
        total: 0,
        today: 0,
      },
      chartSeries: [0, 0],
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: { show: false },
          },
          dataLabels: { enabled: false },
          legend: { show: false },
          labels: ['Kunlik', 'Jami'],
          stroke: { width: 0 },
          colors: [
            $earningsStrokeColor2,
            $themeColors.success,
          ],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: { offsetY: 15 },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      return `${Math.round(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Kunlik',
                    formatter: () => {
                      if (this.statistics && this.statistics.today) {
                        return `${((this.statistics.today / this.statistics.total) * 100).toFixed(1)}%`
                      }
                      return '0%'
                    },
                  },
                },
              },
            },
          },
        },
      },
    }
  },

  created() {
    this.fetchStatistics()
  },

  methods: {
    async fetchStatistics() {
      try {
        const { data } = await axios.get('https://bolajon.mtsh.uz/api/ai-chat/ai-count')
        this.statistics = data
        this.updateChartSeries()
      } catch (error) {
        console.error('Statistikani olishda xatolik:', error)
      }
    },

    updateChartSeries() {
      const dailyPercentage = (this.statistics.today / this.statistics.total) * 100;
      const remainingPercentage = 100 - dailyPercentage;

      this.chartSeries = [
        Math.round(dailyPercentage),
        Math.round(remainingPercentage)
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.earnings-card {
  .stat-title {
    color: #6e6b7b;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .stat-value {
    color: #5e5873;
    font-size: 1.8rem;
    font-weight: 600;
  }
}
</style>
